import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import "./Sitenas.css";
import Nas from "../sitenas/sitenas_img/Nas.svg";
export default function Sitenas() {
    return (
        <>
        <Navbar/>
        <div className="sitenasBox" >
           <h2>Сайт и приложение находится в стадии обработки посмотрите пока </h2>
           <a href="Retail">Pcassa - для автоматизации магазина</a>
           <img src={Nas} />
        </div>
        <Footer/>
        </>
    )}