import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import "./Cateringnas.css";
import Nas from "../cateringnas/cateringnas_img/Nas.svg";
export default function Cateringnas() {
    return (
        <>
        <Navbar/>
        <div className="cateringnasBox" >
           <h2>Pcassa - для автоматизации общепита находится в стадии обработки посмотрите пока </h2>
           <a href="Retail">Pcassa - для автоматизации магазина</a>
           <img src={Nas} />
        </div>
        <Footer/>
        </>
    )}