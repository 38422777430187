import { combineReducers, createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { Token } from "./globalstate";

// import { useSelector } from "react-redux";
// import { create } from "yup/lib/Reference";

  //  //////////////////
  // export const URL = "https://owa.pcassa.ru:5011/api/v1"
  export const URL = "https://owa.pcassa.ru:443"


  export const fetchTarif = createAsyncThunk(
    'Tarif/fetchTarif',
      async function() {
       const response = await fetch(`${URL}/gettarifesforview/ru`)
 
       const data = await response.json()
 
       return data
     }
 )
 
   
 export const TarifState = createSlice({
   name: "Tarif",
   initialState: {
     tarif:null,
   },
   reducers:{
     settarif:(state,{payload})=>{
       state.tarif = payload
     },
   },
   extraReducers:{
     [fetchTarif.pending]:(state)=>{
       state.tarif = 'loding'
       state.error = null
     },
     [fetchTarif.fulfilled]:(state,payload)=>{
       state.tarif = payload
     },
     [fetchTarif.rejected]:(state)=>{
       state.tarif = 'rejected'
       state.error = null
     }
   }
 });

 export const fetchWallet = createAsyncThunk(
  'Wallet/fetchWallet',
    async function() {
     const response = await fetch(`${URL}/getpersonal/ru`)

     const data = await response.json()

     return data
   }
)

 
export const WalletState = createSlice({
 name: "Wallet",
 initialState: {
  Wallet:null,
 },
 reducers:{
   setwallet:(state,{payload})=>{
     state.Wallet = payload
   },
 },
 extraReducers:{
   [fetchWallet.pending]:(state)=>{
     state.Wallet = 'loding'
     state.error = null
   },
   [fetchWallet.fulfilled]:(state,payload)=>{
     state.Wallet = payload
   },
   [fetchWallet.rejected]:(state)=>{
     state.Wallet = 'rejected'
     state.error = null
   }
 }
});
















export const SummState = createSlice({
  name: "Summ",
  initialState: {
    Summ:null,
    SummId:null
  },
  reducers:{
    setSumm:(state,{payload})=>{
      state.Summ = payload
    },
    setSummId:(state,{payload})=>{
      state.SummId = payload
    }
  }
});

 
export const DetailState = createSlice({
  name: "Detail",
  initialState:{
    Detail:null,
    Detail_id:null,
  },
  reducers:{
    setDetail:(state,{payload})=>{
      state.Detail = payload 
    },
    setDetail_id:(state,{payload}) =>{
      state.Detail_id = payload
    },
  }
})
     
export const TokenState = createSlice({
  name: "Token",
  initialState: {
    Token:null
  },
  reducers: {
    setToken:(state,{payload})=>{
      state.Token = payload
    },
  },
 
});

export const CounternerStete = createSlice({

  name:"Counterner",
  initialState:{
    Counterner:null,
  },
  reducers:{
    setCounterner:(state,{payload})=>{
    state.Counterner= payload
  },
},
})

export const EmailSteate =createSlice({
  name:"Email",
  initialState: {
    Email:null
  },
  reducers: {
    setEmail:(state,{payload})=>{
      state.Email = payload
    }
  }
})

export const fetcheUser = createAsyncThunk(
  'User/fetcheUser',
  async function(){
    let response = await fetch(`${URL}/signin/`)
    let data = response.json()
    return data;
  }
  
)
// export const fetcheToken_App = createAsyncThunk(
//   'Token_App/fetcheToken_App',
// async function (){
//   setInterval(() => {
//      fetch('http://192.168.0.107:8000/refreshtoken/',{
//     method: 'POST',
//     headers:{
//       'Content-Type':  'application/json', 
//       'Authorization':` Bearer ${Token_App.refresh_token}`
//     },
//     body:JSON.stringify({refresh_token:Token_App.refresh_token}),
//   })
//   .then((response) => response.json())
//   .then((data)=>{

//   })  

//   }, 10000);
 
   
// })

// export const  Token_AppState = createSlice({
//   namee:'Token_App',
//   initialState:{
//     Token_App:{}
//   },
//   reducers: {
//     setToken_App:(state,{payload})=>{
//       state.Token_App =payload
//     },
//   },
//   extraReducers:{
//     [fetcheToken_App.pending]:(state)=>{
//       state.Token_App = 'loding'
//       state.error = null
//     },
//     [fetcheToken_App.fulfilled]:(state,payload)=>{
//       state.Token_App= payload
//     },
//     [fetcheToken_App.rejected]:(state)=>{
//       state.Token_App = 'rejected'
//       state.error = null
//     }
//   }
// })


export const UserState = createSlice({
  name:"User",
  initialState: {
    User:null
  },
  reducers: {
    setUser:(state,{payload}) =>{
      state.User = payload 
    }
  },
  extraReducers:{
    [fetcheUser.pending]: (state,{payload})=>{
      state.User = payload
    },
    [fetcheUser.fulfilled]: (state,{payload})=>{
      state.User = payload
    },
    [fetcheUser.rejected]: (state,{payload}) =>{
      state.User = payload
    }
  },
  
  
})

export const NavState = createSlice({
 name:"Nav",
 initialState: {
  Nav:null
 },
 reducers: {
  setNav:(state,{payload}) =>{
    state.Nav = payload 
  }
  },
  
})



export const fetcheSignin = createAsyncThunk(
  'TokenLive/fetcheSignin',
)

export const TokenLiveState = createSlice({
  name:'TokenLive',
  initialState:{
    TokenLive:{}
  },
  reducers: {
    setTokenLive:(state,{payload})=>{
      state.TokenLive = payload
    },
  },
  extraReducers:{
    [fetcheSignin.pending]:(state)=>{
      state.TokenLive = 'loding'
      state.error = null
    },
    [fetcheSignin.fulfilled]:(state,payload)=>{
      state.TokenLive= payload
    },
    [fetcheSignin.rejected]:(state)=>{
      state.TokenLive = 'rejected'
      state.error = null
    }
  }
});


export const rootReducer = combineReducers({
  Token:TokenState.reducer,
  Tarif:TarifState.reducer,
  Wallet:WalletState.reducer,
  Email:EmailSteate.reducer,
  Nav:NavState.reducer,
  Summ:SummState.reducer,
  Detail:DetailState.reducer,
  Detail_id:DetailState.reducer,
  Counterner:CounternerStete.reducer,
});

export const { setToken } = TokenState.actions;
export const { settarif } = TarifState.actions;
export const {  setTokenLive } = TokenLiveState.actions;
export const { setEmail } = EmailSteate.actions;
export const { setNav } = NavState.actions;
export const { setSumm ,setSummId} = SummState.actions;
export const {setDetail} = DetailState.actions;
export const {setDetail_id} = DetailState.actions;
export const { setCounterner} = CounternerStete.actions





