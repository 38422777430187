import "./Cabinet.css";
import Navbar from "../navbar/Navbar";
import { useCallback, useEffect, useMemo, useState } from "react";
import axios from 'axios'
import React from 'react';
 import { useFormik } from 'formik';
 import * as Yup from 'yup'
 import { Link } from "react-router-dom";
 import { useNavigate } from "react-router-dom";
 import {useDispatch, useSelector} from 'react-redux'
 import { Token } from "../store/globalstate";
import { setDetail, setDetail_id, setNav,URL } from "../store/rootReducer";
import Konp from "../cabinet/cabinet_img/Konp.svg"
import Sms from "../cabinet/cabinet_img/Sms.svg"
import Tele from "../cabinet/cabinet_img/Tele.svg"
import link from "../cabinet/cabinet_img/link.svg"
import android from "../cabinet/cabinet_img/android.svg"
import Apel from "../cabinet/cabinet_img/Apel.svg"
import Linux from "../cabinet/cabinet_img/Linux.svg"
import windows from "../cabinet/cabinet_img/windows.svg"
import id_card from "../cabinet/cabinet_img/id_card.svg"


export default function Cabinet() {
  let RefreshTokens=localStorage.getItem("RefreshToken")
  let  Tokens =localStorage.getItem("Tokens")
    const [cabinet,setcabinet] = useState('')
    const [info,setinfo] = useState('')
    const [elements, setelements]= useState("")
    const dispatch = useDispatch()
    const [links, setlinks]= useState("")
    const navigate = useNavigate();
    const Token_bild = useSelector(Token)

    

    function getCookie(name){
      const cDecoded =
      decodeURIComponent(document.cookie);
      const cArray = cDecoded.split(";");
      let result = null;
      cArray.forEach(element =>{
        if(element.indexOf(name)==0){
          result = element.substring(name.length + 1)
        }
      })
      return result;
    }
    
useMemo(async() => {
    await  fetch(`${URL}/tarifdetails`,{
      method: 'POST',
      headers: { 
            'Content-Type':  'application/json', 
           'Authorization':` Bearer ${Tokens}`}, 
      body:JSON.stringify({
        tarif_id:elements,      
      }),
    })
    .then((respons) =>respons.json())
    .then((data) => {
      if(data){
         setinfo(data)
    }
    })  
},[])  

useEffect(() => {
    fetch(`${URL}/signin/?language=ru`, {
        method: 'GET',
        headers: {'Content-Type': 'application/json',
        'Authorization':` Bearer ${Tokens}`
       }

    }).then((respons) => respons.json())
    .then((data) => {
      if(data){
        setcabinet(data)
        dispatch(setNav(data))
        
      }else{navigate("/Signin")}
    })
},[Tokens])

useEffect(() => { 
  fetch(`${URL}/user/links`, { 
      method: 'GET', 
      headers: {'Content-Type': 'application/json', 
      'Authorization':` Bearer ${Tokens}` 
     } 

  }).then((respons) => respons.json()) 
  .then((data) => { 
    if(data){ 
      setlinks(data) 
    } 
  }) 
},[Tokens])  

let Cabinet_links = useCallback(()=>{ 
 
        if(links){ 
        return <div className="linker"> 
                 <div id="linker_itme">
                   <div id="linker_itme_img">
                      <img src={Linux} />
                   </div>
                   <div id="linker_itme_content">
                    <span>Скачать для Linux OS</span>
                    <a target={"_blank"} href={links.web_manager_name[0]}>WEB менеджер </a>
                    <a target={"_blank"} href={links.cass_stantion_name[1]}>Кассовая станция</a>
                   </div>
                 </div>
                 <div id="linker_itme">
                  <div id="linker_itme_img">
                      <img src={windows} />
                  </div>
                   <div id="linker_itme_content">
                      <span>Скачать для Windows </span>
                      <a target={"_blank"} href={links.web_manager_name[0]}>WEB менеджер  </a>
                      <a target={"_blank"} href={links.cass_stantion_name[0]}>Кассовая станция</a>
                   </div>
                 </div>
                 <div id="linker_itme">
                   <div id="linker_itme_img"> 
                      <img src={Apel} />
                   </div>
                   <div id="linker_itme_content">
                      <span>Скачать для IOS </span>
                      <a target={"_blank"} href={links.mobile_manager_name[1]}>Мобильный менеджер </a>
                      <a target={"_blank"} href={links.mobile_cass_name[1]}>Мобильная касса</a>
                   </div>
                  </div>
                 <div id="linker_itme"> 
                  <div id="linker_itme_img">
                    <img src={android} />
                  </div>
                  <div id="linker_itme_content">
                     <span>Скачать для android  </span>
                     <a target={"_blank"} href={links.mobile_manager_name[0]}>Мобильный менеджер </a>
                     <a target={"_blank"} href={links.mobile_cass_name[0]}>Мобильная касса</a>
                   </div>
                 </div>
        </div> 
        
        } 
},[links])




let Cabinet_data = useCallback(()=>{
  return <div className="CabinetData">
  <div className="CabinetData1">
 {cabinet?.tarif_list?.map((element,index)=>{
  if(element.order_state == true){    
       setelements(element.t_id)
       return <div className="CabinetData11">
        <span id="kanac"  type="submit">Ваш тариф:{element.t_name}</span>
        <span>Действует до:{element.end_license}</span>
        <h3>Информация о тарифе</h3>
        <ul>
         <li><span>Кассовая станция</span> <span>{info.cass_stantion_c}</span> </li>
         <li><span>Мобильная касса</span> <span>{info.mobile_cassa_c}</span> </li>
         <li><span>Мобиьный менеджер</span> <span>{info.mobile_manager_c}</span> </li>
         <li><span>WEB менеджер</span> <span>{info.web_manager_c}</span> </li>
         <a onClick={(() =>{navigate('/Tarif')})}>Oстальные тарифы</a>
        </ul>
        </div>  

  }else{
     return <div className="CabinetData12">
       <div className="xcv">
       <h4>Важно:</h4> <p>Вы получили сообщение на вашу электронную почту. Подтвердите его, чтобы активировать тариф</p>
       </div>
     </div>              
  }
  })}
  </div>
  </div> 
},[cabinet],)

return (
    <>
    <Navbar />
       <div className="cabinetBox">
        <div className="cabinetBox1"> 
        <div id="cabinetBox1_h5">
          <h5>Личное информация </h5>
          </div>
          
             <div className="cabinetBox2">
          <div className="cabinetBox21">
            <img src={Konp} /> 
            <p> {cabinet.c_name}</p> 
            </div>
            <div className="cabinetBox22">
              <img src={Sms} />
              <p>  {cabinet.c_email}</p>
         </div>
         <div className="cabinetBox23">
          <img src={Tele} />
           <p> {cabinet.c_phone}</p>
         </div>
         <div className="cabinetBox23">
           <img src={id_card} />
           <p>{cabinet.c_unique_id}</p>
         </div>
          </div> 
        </div>  
          <Cabinet_links/>   
      </div>
      
                <div className="cabinetLin"> <hr></hr></div>
                <div className="cabinetBox3">   
                <Cabinet_data/>
                </div>              
      </>
  )
};
 