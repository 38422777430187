import { Link, Routes, useNavigate } from "react-router-dom";
import Windows from "../home/home_img/Windows.svg";
import Android from "../home/home_img/Android.svg";
import Apple from "../home/home_img/Apple.svg";
import Linux from "../home/home_img/Linux.svg";
import Group1 from "../home/home_img/Group1.svg";
import HomeBox1 from "../home/home_img/HomeBox1.svg";
import HomeBox4 from "../home/home_img/HomeBox4.png";
import Cart from "../home/home_img/Cart.png";
import HomeBox2 from "../home/home_img/HomeBox2.svg";
import Vector from "../home/home_img/Vector.svg";
import Navbar from "../navbar/Navbar";
import Endpart from "../endpart/Endpart";
import Footer from "../footer/Footer";
import axios from "axios";
import "./Home.css";


export default function Home(props) {
  const navigate = useNavigate();
  return (
    <>
      <Navbar />
      <div className="home">
        <div className="homeBox1">
          <div className="homBox1ti">
            <div className="homBox1text">
              <h1>Pcassa - Облачный сервис для автоматизации бизнеса</h1>
              <p>
                Внедрите за 15 минут и начните работать без стартовых затрат
              </p>
            </div>
            <div className="homBox1img">
              <img src={HomeBox1} />
            </div>
          </div>
          <div className="homBox1button">
            {/* <Link id="obsh">
              <span > Общепит</span>
              <img src={FrokAndKnife} alt="nice" />
            </Link>
            <Link >
              <span>Ритейл</span>
              <img src={Cart} />
              </Link> */}
          </div>
        </div>
        <div className="homeBox2">
          <div className="homeBox2text">
            <h1>
              Автоматизация бизнеса по вашим правилам и без лишних проблем
            </h1>
            <p>
              Вам не нужно покупать дорогие сервера и специальные кассы, всю
              автоматизацию можно настроить на любом устройстве и операционной
              системе
            </p>
            <div className="programss">
              <img src={Windows} />
              <img src={Android} />
              <img src={Apple} />
              <img src={Linux} />
            </div>
          </div>
          <div className="img2">
            <img src={HomeBox2} />
          </div>
        </div>
        <div className="homeBox3">
          <div className="homeBox31">
            <div className="homeBoxe3text">
              <h1>Полностью облачное решение</h1>
              <p>
                Используйте все возможности автоматизации с Pcassa и
                контролируйте процессы из любой точки мира
              </p>
            </div>
            <div className="list3">
              <ul>
                <img src={Group1} />
                <li>
                  <span>
                    Отслеживайте основные показатели бизнеса и сравнивайте
                    динамику
                  </span>
                </li>
                <li>
                  <span>Рассчитывайте эффективность сотрудников</span>
                </li>
                <li className="homeBox3li">
                  <span>Управляйте всеми процессами в бизнесе</span>
                </li>
              </ul>
            </div>
            <div className="button3">
              <a>
              <Link to="/Tarif">
            <button type="button">Попробовать</button>
          </Link>
              </a>
            </div>
          </div>
          <div className="image3"></div>
        </div>
        <div className="homeBox4">
          <div className="homeBox4tex">
            <h1>Устанавливается за 15 минут</h1>
            <p>
              Для настройки автоматизации не нужны технические специалисты,  программу легко настроить самостоятельно 
            </p>
          </div>
          <div className="image4">
            <img src={HomeBox4} />
          </div>
        </div>
        {/* <div className="homeBox5">
          <div className="homeBox5h1">
            <h1>
              Попробуйте бесплатно прямо сейчас и не платите пока в вашем меню
              до 20 позиций
            </h1>
          </div>
          <div className="homeBox51">
            <div className="homeBox5Step1">
              <div className="homeBox5Step11">
                <img src={HomeBox5Step1} />
              </div>
              <div className="homeBox5Step6">
                <h1>Шаг 1</h1>
                <p>Скачайте наше приложение на смартфон или планшет</p>
              </div>
            </div>
            <div className="homeBox5Step2">
              <div className="homeBox5Step22">
                <img src={HomeBox5Step2} />
              </div>
              <div className="homeBox5Step4">
                <h1>Шаг 2</h1>
                <p>Внесите позиции в меню</p>
              </div>
            </div>
            <div className="homeBox5Step3">
              <div className="homeBox5Step33">
                <img src={HomeBox5Step3} />
              </div>
              <div className="homeBox5Step5">
                <h1>Шаг 3</h1>
                <p>Пользуйтесь! Да, всё настолько просто!</p>
              </div>
            </div>
          </div>
          <div className="button5">
          <Link to="/Tarif">
            <button type="button">Попробовать </button>
          </Link>
          </div>
        </div> */}
        <div className="homeBox6">
          <div className="homeBox6text">
            <h1>Простые API интеграции</h1>
            <p>
              В Pcassa уже есть интеграции с самыми популярными сервисами, а
              новые интеграции внедряются легко и удобно благодаря простой и
              современной архитектуре
            </p>
          </div>
          <div className="list6">
            <ul>
              <li className="li1">
                <div className="vector1" 
        //         onClick={() => {
        //     let text1 = document.getElementsByClassName("text1")[0]
        //     if(text1.hasAttribute('id')){
        //       text1.removeAttribute("id");
              
        //     }else{
        //       text1.setAttribute("id", "vector1" );
        //     }
        //     let li1 = document.getElementsByClassName("li1")[0]
        //     if(li1.hasAttribute('id')){
        //       li1.removeAttribute("id");
              
        //     }else{
        //       li1.setAttribute("id", "vector1" );
        //     }



        // }}
        >
                  <span>Интеграции с любой CRM системой</span>
                  {/* <img src={Vector} /> */}
                </div>
                <div className="list61">
                  <span className="text1">
                  
                  </span>
                </div>
              </li>
              <li className="li2">
                <div className="vector2" 
        //         onClick={() => {
        //     let text2 = document.getElementsByClassName("text2")[0]
        //     if(text2.hasAttribute('id')){
        //       text2.removeAttribute("id");
              
        //     }else{
        //       text2.setAttribute("id", "vector2" );
        //     }
        //     let li2 = document.getElementsByClassName("li2")[0]
        //     if(li2.hasAttribute('id')){
        //       li2.removeAttribute("id");
              
        //     }else{
        //       li2.setAttribute("id", "vector2" );
        //     }
        // }}
        >
                  <span>Соответствие 54-ФЗ</span>
                  {/* <img src={Vector} /> */}
                </div>
                <div className="list62">
                  <span className="text2">
                    
                  </span>
                </div>
              </li>
              <li className="li3">
                <div className="vector3"
        //         onClick={() => {
        //     let text3 = document.getElementsByClassName("text3")[0]
        //     if(text3.hasAttribute('id')){
        //       text3.removeAttribute("id");
              
        //     }else{
        //       text3.setAttribute("id", "vector3" );
        //     }
        //     let li3 = document.getElementsByClassName("li3")[0]
        //     if(li3.hasAttribute('id')){
        //       li3.removeAttribute("id");
              
        //     }else{
        //       li3.setAttribute("id", "vector3" );
        //     }
        // }}
        >
                  <span>Маркировка</span>
                  {/* <img src={Vector} /> */}
                </div>
                <div className="list63">
                  <span className="text3">
                  </span>
                </div>
              </li>
              <li className="li4">
                <div className="vector4" 
        //         onClick={() => {
        //     let text4 = document.getElementsByClassName("text4")[0]
        //     if(text4.hasAttribute('id')){
        //       text4.removeAttribute("id");
              
        //     }else{
        //       text4.setAttribute("id", "vector4" );
        //     }
        //     let li4 = document.getElementsByClassName("li4")[0]
        //     if(li4.hasAttribute('id')){
        //       li4.removeAttribute("id");
              
        //     }else{
        //       li4.setAttribute("id", "vector4" );
        //     }



        // }}
        >
                  <span>Отправка данных в ОФД</span>
                  {/* <img src={Vector} /> */}
                </div>
                <div className="list64">
                  <span className="text4">
                  </span>
                </div>
              </li>
              <li className="li5">
                <div className="vector5"
        //          onClick={() => {
        //     let text5 = document.getElementsByClassName("text5")[0]
        //     if(text5.hasAttribute('id')){
        //       text5.removeAttribute("id");
              
        //     }else{
        //       text5.setAttribute("id", "vector5" );
        //     }
        //     let li5 = document.getElementsByClassName("li5")[0]
        //     if(li5.hasAttribute('id')){
        //       li5.removeAttribute("id");
              
        //     }else{
        //       li5.setAttribute("id", "vector5" );
        //     }
        // }}
        >
                  <span>ФГИС «Меркурий»</span>
                  {/* <img src={Vector} /> */}
                </div>
                <div className="list65">
                  <span className="text5">
                  </span>
                </div>
              </li>
              <li className="li6">
                <div className="vector6"
        //         onClick={() => {
        //     let text6 = document.getElementsByClassName("text6")[0]
        //     if(text6.hasAttribute('id')){
        //       text6.removeAttribute("id");
              
        //     }else{
        //       text6.setAttribute("id", "vector6" );
        //     }
        //     let li6 = document.getElementsByClassName("li6")[0]
        //     if(li6.hasAttribute('id')){
        //       li6.removeAttribute("id");
              
        //     }else{
        //       li6.setAttribute("id", "vector6" );
        //     }
        // }}
        >
                  <span>ЕГАИС </span>
                  {/* <img src={Vector} /> */}
                </div>
                <div className="list66">
                  <span className="text6">
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Endpart />
      <Footer />
    </>
  );
}

