import "./Mersi.css";
import Girl1 from "../mersi/mersi_img/Girl1.png";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
function Mersi() {
    return (
        <>
        <Navbar />
      <div className="mersi">
        <div className="mersiBox">
            <h1>Спасибо за заявку!</h1>
            <p>Наш менеджер скоро вам перезвонит и ответит на все ваши вопросы</p>
        </div>
        <div className="mersiimg">
        <img src={Girl1} />
        </div>
      </div>
      
      <Footer />
    </>
    );
  }
  
  export default Mersi;