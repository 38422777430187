import "./Telqr.css";
import { Link } from "react-router-dom";
import  Tel from "./telqr_img/tel.png"

export default function Telqr() {
    return(
        <div className="telqr">
            
            <Link>
            <img src={Tel} />
            </Link>
        </div>
    )
}
