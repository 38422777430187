import "./Partner.css";
import Mask from "../partner/partner_img/Mask.svg";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import Pti from "../partner/partner_img/Pti.svg";
import Lep from "../partner/partner_img/Lep.svg";
import Handd from "../partner/partner_img/Handd.svg";
import Gca from "../partner/partner_img/Gca.svg";
import Rectangle1 from "../partner/partner_img/Rectangle1.svg";
import Rectangle2 from "../partner/partner_img/Rectangle2.svg";
import Rectangle3 from "../partner/partner_img/Rectangle3.svg";
import Rectangle4 from "../partner/partner_img/Rectangle4.svg";
import Girl1 from "../partner/partner_img/Girl1.svg";
import Girl from "../partner/partner_img/Girl.png";
import  { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { URL } from "../store/rootReducer";
import React from 'react';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";


export default function Partner() {
    const [showDiv, setShowDiv] = useState(false);
    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState('');
  
    const handleChange = (event) => {
      const value = event.target.value;
  
      if (value.includes('-')) {
        setError('Minus sign not allowed');
      } else {
        setError('');
      }
  
      setInputValue(value);
    };
  
    const handleHideDiv = () => {
      setShowDiv(false);
    };
    async function  Main_Partner(values){  
         fetch(`${URL}/requestcompany/`, {
          method: 'POST', // or 'PUT'
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            fio:values.fio,
            phone:values.phone ,
            email:values.email,
            country:values.country ,
            company_name:values.company_name ,
            costumer_count:values.costumer_count ,
            comments:values.comments ,
                                
                              }),
        })
         .then((response) => response.json())
         .then((data) => {
           if (data.status =='ok'){
            setShowDiv(true);
           }
         },
         
         )
         .catch((error) => {
           console.error('Error:', error);
         });
       
      
                }
                const PartnerForm = () => {
                  const [errorMessage, setErrorMessage] = useState('');
    
                  const formik = useFormik({
                    initialValues: {
                      fio:'',
                      phone: '',
                      email: '',
                      country: '',
                      company_name: '',
                      costumer_count: '',
                      comments: '',
                                
                    },
    
                 

                    validationSchema:Yup.object().shape({
                      fio:Yup.string().min(0,"Заполните обязательное поле").max(40,"максимум 40 сиволов").required('Заполните обязательное поле'),
                      email: Yup.string().email('Invalid email').required('Email is required'),
                      phone: Yup.string().min(11,'Отсутствие сиволов').required('Отсутствие сиволов'),
                      company_name:Yup.string().min(0,"Заполните обязательное поле").max(40,"максимум 40 сиволов").required('Заполните обязательное поле'),
                      costumer_count:Yup.string().min(0,"Заполните обязательное поле").max(10,"максимум 10 сиволов").required('Заполните обязательное поле'),
                      costumer_count: Yup.string()
                      .matches(/^\d+$/, 'Только числа разрешены') // Add regex validation for numbers only
                      .required('Заполните обязательное поле'),
                    }),
    
                    onSubmit: values => {
                        Main_Partner(values)
                    },
    
                  });
                  validationSchema: Yup.object().shape({
                    // other validation rules...
                    costumer_count: Yup.string()
                      .matches(/^\d+$/, 'Только числа разрешены') // Add regex validation for numbers only
                      .required('Заполните обязательное поле'),
                  });
                  const handlePhoneChange = event => {
                    const phoneValue = event.target.value;
                    const sanitizedPhone = phoneValue.replace(/[^+\d]/g, ''); // Remove non-digit characters except '+'
                  
                    if (sanitizedPhone.startsWith('+') && sanitizedPhone.indexOf('+', 1) === -1) {
                      setErrorMessage('');
                      formik.setFieldValue('phone', sanitizedPhone);
                    } else {
                      
                    }
                  };
     
                  

                
                  
                    return(
                      <form onSubmit={formik.handleSubmit}>
                        <div className="PartnerInput">
                        <label  htmlFor="fio">ФИО
                            <input
                            placeholder="ФИО"
                            id="fio"
                            name="fio"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.fio}
                          />  
                            {formik.touched.fio && formik.errors.fio? (
             <div className="basd"><span>{formik.errors.fio}</span></div>
            ) : null}
                           </label>
                        </div>
                        <div className="PartnerInput">
                        <label  htmlFor="phone">Номер телефона
                        <input
                        placeholder="+7 977 351 93-93"
                        id="phone"
                        name="phone"
                        type="tel"
                        onChange={handlePhoneChange} // Updated handler
                       value={formik.values.phone}
                      />
                        {errorMessage && <div className="basd"><span>{errorMessage}</span></div>}
                          {error && <div style={{ color: 'red' }}>{error}</div>}
                          {formik.touched.phone && formik.errors.phone? (
             <div className="basd"><span>{formik.errors.phone}</span></div>
            ) : null}
            
                           </label>

                        </div>
            
                        <div className="PartnerInput">
                        <label  htmlFor="email">Еmail
                            <input
                            placeholder="exampe@mail.ru"
                            id="email"
                            name="email"
                            type="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                          />  
                           {formik.touched.email && formik.email? (
                             <div className="email"><span>{formik.errors.email}</span></div>
                            ) : null}
                           </label>
                        </div>
                        
                        <div className="PartnerInputSelect">
                        <label  htmlFor="country">Страна
                          
                              <select id="continent">
                              <option value={formik.values.country}>Россия</option>
                              <option value={formik.values.country}>Հայաստան</option>
                              
                            </select>
                            
                           
                           </label>
                        </div>
            
                        <div className="PartnerInput">
                        <label  htmlFor="company_name">Название компании
                            <input
                            id="company_name"
                            name="company_name"
                            type="company_name"
                            onChange={formik.handleChange}
                            value={formik.values.company_name}
                          />  
                                    {formik.touched.fio && formik.errors.company_name? (
             <div className="basd"><span>{formik.errors.company_name}</span></div>
            ) : null}
                           </label>
                        </div>
            
                        <div className="PartnerInput">
                        <label  htmlFor="costumer_count">Количество сотрудников
                            <input
                            id="costumer_count"
                            name="costumer_count"
                            type="number"
                           
                            onChange={formik.handleChange}
                            value={formik.values.costumer_count}
                          />  
                          {error && <div style={{ color: 'red' }}>{error}</div>}
                {formik.touched.fio && formik.errors.costumer_count? (
             <div className="basd"><span>{formik.errors.costumer_count}</span></div>
            ) : null}
                           </label>
                        </div>
            
                        <div className="PartnerInputComment">
                        <label  htmlFor="comments">Комментарий
                     
                            <textarea
                            id="comments"
                            name="comments"
                            type="text"
                            rows="10" cols="70"
                            maxlength='500'

                            onChange={formik.handleChange}
                            value={formik.values.comments}
                          />  
                           
                           </label>
                        </div>
                       
                        <button id="otp" type="submit">Отправить</button>
                    </form>
                    )
                };
   
    
    
    return (
        <>
        <Navbar/>
        <div className="Partner">
            <div className="PartnerBox1">
                <div className="PartnerBox1text">
                    <h1>Станьте партнером Pcassa и начните зарабатывать уже сегодня</h1>
                    <p>Получайте доход от каждого привлеченного клиента и получайте доступ к нашей клиентской базе.</p>
                </div>
                <div className="PartnerBox1img">
                <img  src={Mask}/>
                </div> 
                </div>  <h1>Готовы ускорить свой бизнес?</h1>
                <div className="PartnerBox2">
                  
                    <div className="PartnerBox2text">
                        
                        <p><img  src={Pti}/>  Найдите подходящую для вас партнерскую программу - хотите ли вы расширить ассортимент своего портфеля, привлечь новых клиентов или просто использовать бренд Pcassa.</p>
                    </div>
                    <div className="PartnerBox2img"><img  src={Lep}/> </div>
                </div>

                <div className="PartnerBox3">
                    <div className="PartnerBox3text">
                        <p><img  src={Pti}/>  Если вы работаете с клиентами или предприятиями, которым требуется автоматизация для розничной торговли, стать партнером Pcassa может быть идеальным решением.</p>
                    </div>
                    <div className="PartnerBox3img"><img  src={Handd}/> </div>
                </div>

                <div className="PartnerBox4">
                    <div className="PartnerBox4text">
                        <p><img  src={Pti}/>Зарегистрируйтесь за 30 секунд и  станьте частью решения. Расширяйте клиентскую базу, создавая новый регулярный поток доходов.</p>
                    </div>
                    <div className="PartnerBox4img"><img  src={Gca}/> </div>
                </div>
                <div className="PartnerBox5">
                    <h1>В чем преимущества партнерства с нами</h1>
                    <div className="PartnerBox5Box">
                        <div className="PartnerBox5ob">
                        <img  src={Rectangle1}/>
                            <h4>Прозрачная бонусная программа для партнеров</h4>
                        </div>
                        <div className="PartnerBox5ob">
                        <img  src={Rectangle2}/> 
                            <h4>Как партнер Pcassa вы можете выйти на этот рынок и предложить облачное решение без стартовых затрат</h4>
                        </div>
                        <div className="PartnerBox5ob">
                        <img  src={Rectangle3}/> 
                            <h4>Используйте базу клиентов Pcassa и зарабатывайте на сервисе и оборудовании</h4>
                        </div>
                        <div className="PartnerBox5ob">
                           <img  src={Rectangle4}/>
                            <h4>Приглашайте своих клиентов и получайте вознаграждение</h4>
                        </div>
                    </div>
                </div>
                <div>
      
      
      {showDiv && <div className="PartnerBoxTanks">
        <div className="PartnerBoxTanksBox1">
            <h1>Спасибоза заявку!</h1>
            <p>Наш менеджер скоро выйдет на связь.</p>
            <button onClick={handleHideDiv}>Вернуться</button>
        </div>
        <div className="PartnerBoxTanksimg"><img  src={Girl1}/></div>
        </div>}

      {showDiv || <div className="PartnerBoxRequest">
        <h1>Оставьте заявку и мы свяжемся с вами </h1>
        <div className="PartnerBoxRequestBox">
            <div className="PartnerBoxRequestimg"><img  src={Girl}/></div>
            <div className="PartnerBoxRequestinput">
                <PartnerForm/>
               
                </div>
        </div>
        
        </div>}
    </div>
       
        </div>
        <Footer/>
</>
    )}