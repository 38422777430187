import { Link } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import Endpart from "../endpart/Endpart";
import Footer from "../footer/Footer";
import axios from "axios";
import "./Retail.css";
import RetailBox1 from "../retail/retail_img/RetailBox1.png";
import Windows from "../retail/retail_img/Windows.svg";
import Android from "../retail/retail_img/Android.svg";
import Apple from "../retail/retail_img/Apple.svg";
import Linux from "../retail/retail_img/Linux.svg";
import HomeBox2 from "../retail/retail_img/HomeBox2.svg";
import Caption1 from "../retail/retail_img/Caption1.png";
import Caption2 from "../retail/retail_img/Caption2.png";
import Caption3 from "../retail/retail_img/Caption3.png";
import Caption4 from "../retail/retail_img/Caption4.png";
import Terminal from "../retail/retail_img/Terminal.svg";
import Hand from "../retail/retail_img/Hand.svg";
import Gift from "../retail/retail_img/Gift.png";
import Shut from "../retail/retail_img/Shut.svg";
import Grafik from "../retail/retail_img/Grafik.svg";
import Layers2 from "../retail/retail_img/Layers2.png"
import Burg from "../retail/retail_img/Burg.png"

export default function Retail() {
  return (
    <>
      <Navbar />

      <div className="retail">
        <div className="retailBox1">
          <div className="retailBox1text">
            <h1>Pcassa - все, что нужно для автоматизации розничной торговли</h1>
          </div>
          <div className="retailBox1img">
            <img src={RetailBox1} />


          </div>
        </div>
        {/* <div className="retailBox2">
          <div className="retailBox2text">
            <h1>
              Автоматизация бизнеса по вашим правилам и без лишних проблем
            </h1>
            <p>
              Вам не нужно покупать дорогие сервера и специальные кассы, всю
              автоматизацию можно настроить на любом устройстве и операционной
              системе
            </p>
            <div className="programss">
              <img src={Windows} />
              <img src={Android} />
              <img src={Apple} />
              <img src={Linux} />
            </div>
          </div>
          <div className="img2">
            <img src={HomeBox2} />
          </div>
        </div> */}
        <div className="retailBox3">
          <div className="retailBox3ti">
            <h1>Подойдет для любого формата</h1>
          </div>
          <div className="retailBox3img">
            <div className="retailBox3img1">
              <span>
                <img id="xsw" src={Caption4} />
                <h6>Магазин цветов</h6>
              </span>
              <span>
                <img src={Caption2} />
                <h6>Продуктовый магазин</h6>
              </span>
            </div>
            <div className="retailBox3img2">
             
              <span>
                <img  id="cde"  src={Caption1} />
                <h6>Аптека</h6>
              </span>
               <span>
                 <img id="zaq" src={Caption3} />
                <h6>Аксессуары для гаджетов</h6>
              </span>
            </div>
          </div>
        </div>
        <div className="retailBox4">
          <div className="retailBox41"> 
            <div className="retailBox4ti">
              <h1>Удобное кассовое ПО</h1>
              <p>
                Легко добавляйте услугу или товар в чек, предоставляйте скидки,
                продавайте весовой товар
              </p>
            </div>
            <div className="retailBox4list">
              <ul>
                <li>
                  Кассовый терминал можно установить на любое устройство -
                  планшеты, ноутбуки, моноблоки, смартфоны
                </li>
                <li>Печатайте брендированные чеки, храните архив чеков</li>
                <li>Используйте смешанные виды оплаты</li>
              </ul>
            </div>
          </div>
          <div className="retailBox4img">
            <img src={Terminal} />
          </div>
        </div>
        <div className="retailBox5">
          <div className="retailBox5m">
            <div className="retailBox5ti">
              <h1>Контролируйте работу бизнеса со своего смартфона</h1>
              <p>
              Следите за посещаемостью, продажами, работой персонала, 
              получайте регулярные отчеты с полной аналитикой по бизнесу
              </p>
            </div>
            <div className="retailBox5list">
              <ul>
                <li>Анализируйте и сравнивайте основные показатели бизнеса</li>
                <li>
                Контролируйте остатки на складе и получайте напоминания о закупках
                </li>
                <li>Печатайте ценники и проводите инвентаризацию</li>
                <li>
                Настраивайте уровни доступа к управлению для сотрудников
                </li>
              </ul>
            </div>
          </div>
          <div className="retailBox5img">
            <img src={Hand} />
          </div>
        </div>
        {/* <div className="retailBox6">
          <div className="retailBox6tb">
            <div className="retailBox6ti">
              <h1>Попробуйте Pcassa </h1>
              <p>
                Не платите за автоматизацию пока в вашем меню до 20-ти позиций
              </p>
              <div className="retailBox6Button6">
                <a>
                  <Link to="/Tarif">
                  <button type="button">Получить Pсassa</button>
                  </Link>
                </a>
              </div>
            </div>

            <div className="retailBox6img">
              <img src={Gift} />
            </div>
          </div>
        </div>
        <div className="retailBox6Button61">
          <a>
            <button type="button">Управление персоналом</button>
          </a>
        </div> */}
        <div className="retailBox7">
          <div className="retailBox71">
            <div className="retailBox7ti">
              <h1>Следите за работой персонала</h1>
              <p>
              Будьте в курсе всех действий сотрудников, отслеживайте эффективность работы и личные показатели каждого
              </p>
            </div>
            <div className="retailBox7list">
              <ul>
                <li>
                  Просматривайте историю действий сотрудника и отчет по каждому
                  чеку
                </li>
                <li>Получайте уведомления о сомнительных операциях</li>
                <li>Узнавайте кто из сотрудников работает эффективнее</li>
              </ul>
            </div>
          </div>
          <div className="retailBox7img">
            <img src={Shut} />
          </div>
        </div>
        <div className="retailBox8">
          <div className="retailBox8m">
            <div className="retailBox8ti">
              <h1>
                Получайте регулярные подробные отчеты обо всём, что происходит в
                бизнесе
              </h1>
              <p>
                Контролируйте все процессы, отслеживайте динамику продаж,
                эффективность сотрудников, прогнозируйте посещаемость и нагрузку
              </p>
            </div>
            <div className="retailBox8list">
              <ul>
                <li>
                  Используйте стандартные шаблоны отчетов или создавайте
                  собственные
                </li>
                <li>
                  Отслеживайте какие позиции прибыльнее, а сотрудники
                  эффективнее
                </li>
                <li>Контролируйте все доходы и расходы</li>
              </ul>
            </div>
          </div>
          <div className="retailBox8img">
            <img src={Grafik} />
            <div className="burg1">
            </div>
          </div>
        </div>
        <div className="retailBox10">
          <div className="retailBox10tb">
            <div className="retailBox10ti">
              <h1>Оцените удобство Pcassa прямо сейчас</h1>
              <p>
                Просто зарегистрируйтесь в системе, настроить автоматизацию
                можно на любом личном устройстве
              </p>
              <div className="retailBox10Button10">
                  <Link to="/Tarif">
                  <button type="button">Получить Pсassa</button>
                  </Link>
              </div>
            </div>

            <div className="retailBox10img">
              <img src={Layers2} />
            </div>
          </div>
        </div>
      </div>
      <Endpart />
      <Footer />
    </>
  );
}
