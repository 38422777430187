
import Navbar from "../navbar/Navbar";
import "./Uniq.css";
import Logo from "../uniq/uniq_img/Logo.svg";

export default function Uniq() {
    return (
      <>
        <Navbar />
        <div>
        <img src={Logo} />
        <p>На вашу почту отправлены данные регистрации в нашей системе. Используйте Unique code для подключения ваших девайсов.</p>
        <button></button>
        </div>
        
    </>
  );
}
