import { Link } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import Endpart from "../endpart/Endpart";
import Footer from "../footer/Footer";
import axios from "axios";
import "./Download.css";
import Dow from "../download/download_img/Dow.png";
export default function Download() {
    return (
      <>
        <Navbar />
        <div className="download">
        <div className="downloadBox">
          <div className="downloadBoxti">
            <div className="downloadBox1text">
                <h1>Скачайте Pcassa</h1>
                <p>Выберите желаемую платформу и загрузка начнется автоматически</p>

            </div>
            <div className="downloadBoximg">
            <img src={Dow} />
            </div>
            </div>
            <div className="downloadBox2text">
            <select id="list">
                    <option value="Windows">Windows</option>
                    <option value="Android">Android</option>
                    <option value="Apple">Apple</option>
                    <option value="Linux">Linux</option>
                </select>
                <p>Скачивая приложение вы соглашаетесь с условиями обработки данных</p>
            </div>


        </div>
        </div>

          <Endpart />
      <Footer />
    </>
  );
}