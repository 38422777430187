import "./Transferok.css";
import Vecto from "../transferok/transferok_img/Vecto.svg"
import Navbar from "../navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export default function  Transferok(props) {
    const navigate = useNavigate();
    return (
        <>
        <Navbar/>
        <div className="transferok">
            <div className="transferokBox">
                <div className="transferokBox2">
                <div className="transferokBox1"> 
                
                <div className="transferokimg"> <img src={Vecto} /></div>
                <div className="transferoktext"><span>Ваш запрос успешно отправлен. Мы выставим вам счет,
                    и после оплаты на вашу почту будет отправлено ссылки на продукты. </span></div>
                 </div>
                </div>
                
            <div className="transferokButton">
                <Link to="/Cabinet">
                <button   type="button" >Хорошо</button>
                </Link>
             </div>
                 
               
              
            </div>
            </div>
       </>
        )}