import "./Navmenu.css";
import { Link } from "react-router-dom";
import Wha from "./navbarsvg/Wha.svg";
import Telg from "./navbarsvg/Telg.svg";
import Logo from "./navbarsvg/Logo.svg";
import Burger from "./navbarsvg/Burger.svg";

function Navmenu() {
  return (
   <div className="navmenu">
    <div className="navmenuimg">
      <div className="navmenuimg1">
        <a href="/home">
            <img src={Logo} />
          </a></div>
      <div className="navmenuimg2">
      <a>
            <img src={Burger} />
          </a>
      </div>
    </div>
    <div className="navmenulist">
    <ul>
            <li>
              <a href="Catering">Общепит</a>
            </li>
            <li>
              <a href="/Retail">Ритейл</a>
            </li>
            <li>
              <a href="/Tarif">Тарифы</a>
            </li>
            <li>
              <a href="/Download">Скачать</a>
            </li>
            <li>
              <a href="/site">Сайт и приложение</a>
            </li>
          </ul>
    </div>
    <div className="navmenuButton">
    <Link to="/download">
            <button type="button">Попробовать бесплатно</button>
          </Link>
          
    </div>
    <p>+7(495) 845-17-49</p>
    <div className="telWha">
    <img src={Telg} />
    <img src={Wha} />
    </div>
    
   </div>
  );
}

export default Navmenu;
