import "./TarifOwn.css";
import { useCallback, useEffect, useState } from "react";
import { Await, Link } from "react-router-dom";
import { Token, Wallet } from "../store/globalstate";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tarif_a } from "../store/globalstate";
import { fetchTarif, fetchWallet } from "../store/rootReducer";
import Vector_1 from './tarifOwn_img/Vector_1.svg'
import { Summ, SummId } from "../store/globalstate";
import { Counterne } from "../store/globalstate";
import { useMemo } from 'react';
import Country from '../country/Country.json'
import Linkcountry from '../country/Linkcountry.json'
import { useFormik } from 'formik';
import Vector from "../registrationpaid/registrationpaid_img/Vector.svg";
import * as Yup from 'yup'
import { setSumm, setSummId, Tokenn } from "../store/rootReducer";
import Group4 from "../tarifOwn/tarifOwn_img/Group4.svg";
import Group5 from "../tarifOwn/tarifOwn_img/Group5.svg";
import Group3 from "../tarifOwn/tarifOwn_img/Group3.svg";
import Visa from "../bycard/bycard_img/Visa.svg";
import Master from "../bycard/bycard_img/Master.svg";
import Amer from "../bycard/bycard_img/Amer.svg";
import Chg from "../bycard/bycard_img/Chg.svg";
import Vect from "../bycard/bycard_img/Vect.svg";
import Bycard from "../bycard/Bycard";
import Transfer from "../transfer/Transfer";
import check_icon from './tarifOwn_img/check_icon.svg'
import plus from './tarifOwn_img/plus.svg'
import { setCounterner ,URL } from "../store/rootReducer";


export default function  TarifOwn(props) {
    let  Tokens =localStorage.getItem("Tokens")
    let RefreshTokens=localStorage.getItem("RefreshToken")
     
  const [a ,seta] = useState({
    acc_contact_name:'',
    acc_org_name: '',
    acc_email: '',
    acc_phone: '',
    acc_address:'',
    acc_inn: '',
    acc_country:'',
  })
    const Token_bild = useSelector(Token)
    const[tarifo,settarifo] =useState('')
    const [error,seterror] = useState(false)
  const [errorpar,seterrorpar] = useState(false)
  const [errorstr,seterrorstr] = useState(false)
    const [counter, setCounter] = useState(1)
    const [counter1, setCounter1] = useState(1)
    const [counter2, setCounter2] = useState(1)
    const [counter3, setCounter3] = useState(1)
    const [transferok,settransferok] = useState(false)
    const [bycard,setbycard] = useState(false)
    const [bycard_2,setbycard_2] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [list, setlist] = useState({
        tarif_id: '',
        order_summ: '',
        cass_stantion_count: '',
        mobile_cass_count: '',
        mobile_manager_count: '',
        web_manager_count: '',
        client_token: "string",
        interval: 1
    })
    const Counter = useSelector(Counterne)
    const Summs = useSelector(Summ)
    const SummsId = useSelector(SummId)

    let sumar = ''
    let mobile_cass = ''
    let web_manager = ''
    let mobile_manager = ''
    let cass_stantion = ''

    window.addEventListener('click', (event) => {
        let acc_country_name_bottom = document.querySelector('.acc_country_name_bottom')
       if(event.target.id == 'acc_country_name_bottom' || event.target.id == 'acc_country_name_ul' || event.target.id == 'acc_country_name_span'){
    
       }else{
        acc_country_name_bottom?.removeAttribute('class')
       }
      })

    
useMemo(async () =>{
    await  dispatch(fetchTarif())
    await  dispatch(fetchWallet())
    },[])

    let Tarif_data_list = useSelector(Tarif_a)
    let Wallets = useSelector(Wallet)



const SignupForm = () => {
  const [errorMessage, setErrorMessage] = useState('');
        const [CountryS, setCountryS] = useState("Страна*")
        const formik = useFormik({
          initialValues: a,
          validationSchema:Yup.object().shape({
            acc_contact_name: Yup.string().min(0, 'Заполните поле').max(40,'максимум 40 сиволов').required('Заполните  поле'),
            acc_org_name: Yup.string().min(0, 'Заполните  поле').max(40,'максимум 40 сиволов').required('Заполните  поле'),
            acc_address: Yup.string().min(0, 'Заполните  поле').max(100,'максимум 100 сиволов').required('Заполните  поле'),
            acc_email: Yup.string().email('Invalid email').required('Электронная почта обязательна'),
            acc_phone: Yup.string().min(11,'Отсутствие сиволов').required('Отсутствие сиволов'),
            acc_inn: Yup.string().min(0, 'Заполните поле').max(15,'максимум 15 сиволов').required('Заполните поле'),
            costumer_count: Yup.string()
            .matches(/^\d+$/, 'Только числа разрешены') // Add regex validation for numbers only
            .required('Заполните обязательное поле'),
          }),
          

          

          onSubmit: values => {
            if(values.acc_email){
            fetch(`${URL}/orderemail`, {
                method: 'POST', // or 'PUT'
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                  acc_contact_name:values.acc_contact_name ,
                  acc_org_name:values.acc_org_name ,
                  acc_email:values.acc_email,
                  acc_phone:values.acc_phone ,
                  acc_address:values.acc_address ,
                  acc_inn:values.acc_inn,
                  acc_country:values.acc_country,
                }),
              })
               .then((response) => response.json())
               .then((data) => {
                 if(data == 'grancvac'){
                  setbycard_2(false)
                  alert('Ваша заявка принята, Наши специалисты свяжуться с вами в скором времени')  
                  let request0 = document.querySelector('#request0')
                  let request1 = document.querySelector('#request1')
                  let request2 = document.querySelector('#request2')
                  let request3 = document.querySelector('#request3')

                  request0.removeAttribute('class')
                  request1.removeAttribute('class')
                  request2.removeAttribute('class')
                  request3.removeAttribute('class')
                  
                 }
                })
          }
        }
        });
        validationSchema: Yup.object().shape({
          // other validation rules...
          costumer_count: Yup.string()
            .matches(/^\d+$/, 'Только числа разрешены') // Add regex validation for numbers only
            .required('Заполните обязательное поле'),
        });
        const handlePhoneChange = event => {
          const phoneValue = event.target.value;
          const sanitizedPhone = phoneValue.replace(/[^+\d]/g, ''); // Remove non-digit characters except '+'
        
          if (sanitizedPhone.startsWith('+++') && sanitizedPhone.indexOf('+', 1) === -1) {
            setErrorMessage('');
            formik.setFieldValue('acc_phone', sanitizedPhone);
          } else {
            // setErrorMessage("Правильный взгляд <<+7 977 351 93-93>>.");
          }
        };

        return (
          <form onSubmit={formik.handleSubmit}>
          
            <div className="registrationBox2im">
              <div className="registrationBox21">
            <label  htmlFor="acc_contact_name"> </label>
            <input
              placeholder="Имя*"
              id="acc_contact_name"
              name="acc_contact_name"
              type="acc_contact_name"
              onChange={formik.handleChange}
              value={formik.values.acc_contact_name}
            />  
             {formik.touched.acc_contact_name && formik.errors.acc_contact_name ? (
               <div className="acc_contact_name"><span>{formik.errors.acc_contact_name}</span></div>
              ) : null}
              </div>
              <div className="registrationBox22">
              <label  htmlFor="acc_contact_name"></label>
            <input
              placeholder="ИНН*"
              id="acc_inn"
              name="acc_inn"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.acc_inn}
            />  
             {formik.touched.acc_inn&& formik.errors.acc_inn ? (
               <div className="acc_inn"><span>{formik.errors.acc_inn}</span></div>
              ) : null}
              
            </div>
            </div>
            <div className="registrationpaidBox6">
            <div className="registrationpaidBox61">
            <label  htmlFor="acc_country_name">
                 <ul id="acc_country_name_ul" value={formik.values.acc_country} onClick={(event)=> {
                   event.currentTarget.nextSibling.setAttribute('class','acc_country_name_bottom')
                 }}><span id='acc_country_name_span' >{CountryS}</span><img  src={Vector} id="acc_country_name_span"/></ul>
                 <ul id="acc_country_name_bottom">
                  {Country.map((el,index) => {
                  return <li key={index} onClick={(event) => {
                    event.currentTarget.parentElement.removeAttribute('class')
                    setCountryS(event.currentTarget.children[1].innerText)
                    formik.values.acc_country = event.currentTarget.children[1].innerText
                  }}>
                         <img src={el.img} id="acc_country_name_span" /><span id="acc_country_name_span">{el.country_name}</span>
                       </li>
                  })}
               </ul>
            </label> 
             {formik.touched.acc_country&& formik.errors.acc_country ? (
               <div className="acc_country"><span>{formik.errors.acc_country}</span></div>
              ) : null}

              </div>
              <div className="registrationpaidBox61">
              <label  htmlFor="acc_org_name"> </label>
            <input
              placeholder="Организация*"
              id="acc_org_name"
              name="acc_org_name"
              type="acc_org_name"
              onChange={formik.handleChange}
              value={formik.values.acc_org_name}

               />
               {formik.touched.acc_org_name && formik.errors.acc_org_name ? (
               <div className="acc_org_name"><span>{formik.errors.acc_org_name}</span></div>
              ) : null}
          
              </div>
              </div>
            <div className="registrationBox3">
               <label  htmlFor="acc_address"></label>
            <input
              placeholder="Адрес организации*"
              id="acc_address"
              name="acc_address"
              type="acc_address"
              onChange={formik.handleChange}
              value={formik.values.acc_address}

               />
               {formik.touched.acc_address&& formik.errors.acc_address ? (
               <div className="acc_address"><span>{formik.errors.acc_address}</span></div>
              ) : null}
            </div>
            <div className="registrationBox4">
               <label  htmlFor="acc_email"></label>
            <input
              placeholder="E-mail*"
              id="acc_email"
              name="acc_email"
              type="email"
              onChange={formik.handleChange}
              value={formik.values.acc_email}

               />
               {formik.touched.acc_email && formik.errors.acc_email ? (
               <div className="acc_email"><span>{formik.errors.acc_email}</span></div>
              ) : null}
            </div>
            <div className="registrationBox4">
                        <label  htmlFor="acc_phone">
                        <input
                        placeholder="+7 977 351 93-93"
                        id="acc_phone"
                        name="acc_phone"
                        type="tel"
                        onChange={handlePhoneChange} // Updated handler
                       value={formik.values.acc_phone}
                      />
                        {errorMessage && <div className="basd"><span>{errorMessage}</span></div>}
                          {error && <div style={{ color: 'red' }}>{error}</div>}
                          {formik.touched.acc_phone && formik.errors.acc_phone? (
             <div className="basd"><span>{formik.errors.acc_phone}</span></div>
            ) : null}
            
                           </label>

                        </div>


            
            <div className="registrationButon1">
                <button  type="submit">отправить</button>
           </div>       
       </form>
        );
};
            

    return (
    <div className="tarifOwn">
        <div className="tarifOwnBox">
        <div id="tarif_data_content">
             {Tarif_data_list?.payload?.map((el,index1) => {
               return <section className="barev" key={index1} id={el.tarif_id}>
                      <div id="tarif_data_content_top">
                      <h2>{el.tarif_names}</h2>
                      {index1 <= 0 ?  <img id="ikon1" src={Group5}/>:''} 
                      {index1 <= 1  && index1 > 0  ? <h2 id="ikon2">2500руб</h2> : ''}
                      {index1 <= 1 && index1 > 0  ? <img id="ikon3" src={Group3}/> : ''}
                    
                        {index1 > 0 ? <div id="tarif_data_content_header"><h2>{el.tarif_month_prices}</h2><h2>руб</h2><span id="vmesec">/в месяц</span></div>:''}
                      </div>
                      <ul id="tarif_data_content_content">
                        <li>
                            <div id="tarif_data_content_content_add">
                            
                            <span id="tarif_data_content_content_add_wallet">
                                <div>{el.inner_content.cassa_names}</div>
                              {index1 > 0 ?  <div id="tarif_data_content_content_add_wallet_itme">+ <span>{el.inner_content.cassa_prices}</span>руб.</div>:''}
                            </span>

                            </div><span id="tarif_data_content_content_add_span">{index1 > 0 ? <a id="minus"   onClick={(prev) => {if(index1 <= 1  && index1 > 0 ){
                              if(+prev.currentTarget.nextSibling.innerText > 1){
                                prev.currentTarget.nextSibling.innerText = +prev.currentTarget.nextSibling.innerText - 1
                                prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[3].children[0].innerText = +prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[3].children[0].innerText - (+prev.currentTarget.parentElement.parentElement.children[0].children[0].children[1].children[0].innerText)                    
                               }
                            }else{
                              if(+prev.currentTarget.nextSibling.innerText > 1){
                                 prev.currentTarget.nextSibling.innerText = +prev.currentTarget.nextSibling.innerText - 1
                                 prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[1].children[0].innerText = +prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[1].children[0].innerText - (+prev.currentTarget.parentElement.parentElement.children[0].children[0].children[1].children[0].innerText)                    
                                }
                            }
                              
                                
                               }}>-</a>:''}{index1 > 0 ? <li>1</li> : <span>1</span>}{index1 > 0 ? <a id="plus"   onClick={(prev) => {if(index1 <= 1  && index1 > 0 ){
                                prev.currentTarget.previousSibling.innerText = +prev.currentTarget.previousSibling.innerText + 1;
                                prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[3].children[0].innerText = +prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[3].children[0].innerText +  (+prev.currentTarget.parentElement.parentElement.children[0].children[0].children[1].children[0].innerText)
                               }else{
                                 prev.currentTarget.previousSibling.innerText = +prev.currentTarget.previousSibling.innerText + 1;
                                prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[1].children[0].innerText = +prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[1].children[0].innerText +  (+prev.currentTarget.parentElement.parentElement.children[0].children[0].children[1].children[0].innerText)
                               }
                               
                               }}>+</a>:''}</span>
                                 
                        </li>
                        <li><div id="tarif_data_content_content_add">
                            <span id="tarif_data_content_content_add_wallet">
                                <div>{el.inner_content.manager_names}</div>
                               {index1 > 0 ? <div id="tarif_data_content_content_add_wallet_itme">+ <span>{el.inner_content.manager_prices}</span>руб.</div>:''}
                            </span>
                        </div><span id="tarif_data_content_content_add_span">{index1 > 0 ? <a id="minus" onClick={(prev) => {
                          if(index1 == 1){
                            if(+prev.currentTarget.nextSibling.innerText > 1){
                              prev.currentTarget.nextSibling.innerText = +prev.currentTarget.nextSibling.innerText - 1
                              prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[3].children[0].innerText = +prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[3].children[0].innerText - (+prev.currentTarget.parentElement.parentElement.children[0].children[0].children[1].children[0].innerText)
                            }
                          }else{
                            if(+prev.currentTarget.nextSibling.innerText > 1){
                            prev.currentTarget.nextSibling.innerText = +prev.currentTarget.nextSibling.innerText - 1
                            prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[1].children[0].innerText = +prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[1].children[0].innerText - (+prev.currentTarget.parentElement.parentElement.children[0].children[0].children[1].children[0].innerText)
                            }
                          }  
                        }}>-</a>:''}{index1 > 0 ? <li>1</li> : <span>1</span>}{index1 > 0 ? <a id="plus"  onClick={(prev) => { if(index1 <= 1  && index1 > 0){
                          prev.currentTarget.previousSibling.innerText = +prev.currentTarget.previousSibling.innerText + 1
                            prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[3].children[0].innerText = +prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[3].children[0].innerText + (+prev.currentTarget.parentElement.parentElement.children[0].children[0].children[1].children[0].innerText)
                        }else{
                          prev.currentTarget.previousSibling.innerText = +prev.currentTarget.previousSibling.innerText + 1
                            prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[1].children[0].innerText = +prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[1].children[0].innerText + (+prev.currentTarget.parentElement.parentElement.children[0].children[0].children[1].children[0].innerText)
                        }

                            
                            }}>+</a>:''}</span></li>
                        <li><div id="tarif_data_content_content_add">

                           <span id="tarif_data_content_content_add_wallet">
                                <div>{el.inner_content.web_names}</div>
                               {index1 > 0 ? <div id="tarif_data_content_content_add_wallet_itme">+ <span>{el.inner_content.web_prices}</span>руб.</div>:''}
                            </span>

                        </div><span id="tarif_data_content_content_add_span">{index1 > 0 ? <a id="minus"   onClick={(prev) => {if(index1 == 1){
                         
                             if(+prev.currentTarget.nextSibling.innerText > 1){
                              prev.currentTarget.nextSibling.innerText = +prev.currentTarget.nextSibling.innerText - 1
                              prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[3].children[0].innerText = +prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[3].children[0].innerText - (+prev.currentTarget.parentElement.parentElement.children[0].children[0].children[1].children[0].innerText)
                            }
                        }else{
                            if(+prev.currentTarget.nextSibling.innerText > 1){
                            prev.currentTarget.nextSibling.innerText = +prev.currentTarget.nextSibling.innerText - 1
                            prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[1].children[0].innerText = +prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[1].children[0].innerText - (+prev.currentTarget.parentElement.parentElement.children[0].children[0].children[1].children[0].innerText)
                        }
                        }
                         
                        }}>-</a>:''}{index1 > 0 ? <li>1</li> : <span>1</span>}{index1 > 0 ? <a id="plus"   onClick={(prev) => { if(index1 <= 1  && index1 > 0){
                            prev.currentTarget.previousSibling.innerText = +prev.currentTarget.previousSibling.innerText + 1
                            prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[3].children[0].innerText = +prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[3].children[0].innerText + (+prev.currentTarget.parentElement.parentElement.children[0].children[0].children[1].children[0].innerText)
                        }else{
                            prev.currentTarget.previousSibling.innerText = +prev.currentTarget.previousSibling.innerText + 1
                            prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[1].children[0].innerText = +prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[1].children[0].innerText + (+prev.currentTarget.parentElement.parentElement.children[0].children[0].children[1].children[0].innerText)
                        }
      
                           
                            }}>+</a>:''}</span></li>
                        <li><div id="tarif_data_content_content_add">

                           <span id="tarif_data_content_content_add_wallet">
                                <div>{el.inner_content.mobile_cassa_names}</div>
                                {index1 > 0 ?<div id="tarif_data_content_content_add_wallet_itme">+ <span>{el.inner_content.mobile_prices}</span>руб.</div>:''}
                            </span>
                    

                        </div><span id="tarif_data_content_content_add_span">{index1 > 0 ? <a id="minus"   onClick={(prev) => {if(index1 <= 1  && index1 > 0){
                      if(+prev.currentTarget.nextSibling.innerText > 1){
                            prev.currentTarget.nextSibling.innerText = +prev.currentTarget.nextSibling.innerText - 1
                            prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[3].children[0].innerText = +prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[3].children[0].innerText - (+prev.currentTarget.parentElement.parentElement.children[0].children[0].children[1].children[0].innerText)
                            }
                        }else{
                           if(+prev.currentTarget.nextSibling.innerText > 1){
                            prev.currentTarget.nextSibling.innerText = +prev.currentTarget.nextSibling.innerText - 1
                            prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[1].children[0].innerText = +prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[1].children[0].innerText - (+prev.currentTarget.parentElement.parentElement.children[0].children[0].children[1].children[0].innerText)
                            }
                        }
                           
                            }}>-</a>:''}{index1 > 0 ? <li>1</li> : <span>1</span>}{index1 > 0 ? <a id="plus"   onClick={(prev) => { if(index1 <= 1  && index1 > 0){
                              prev.currentTarget.previousSibling.innerText = +prev.currentTarget.previousSibling.innerText + 1
                              prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[3].children[0].innerText = +prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[3].children[0].innerText + (+prev.currentTarget.parentElement.parentElement.children[0].children[0].children[1].children[0].innerText)
                            }else{
                              prev.currentTarget.previousSibling.innerText = +prev.currentTarget.previousSibling.innerText + 1
                            prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[1].children[0].innerText = +prev.currentTarget.parentElement.parentElement.parentElement.parentElement.children[0].children[1].children[0].innerText + (+prev.currentTarget.parentElement.parentElement.children[0].children[0].children[1].children[0].innerText)
                            }
                                }}>+</a>:''}</span></li>
                      </ul>
                       <li id="button_li"><button onClick={(event)=>{
                        let request0 = document.querySelector('#request0')
                        let request1 = document.querySelector('#request1')
                        let request2 = document.querySelector('#request2')
                        let request3 = document.querySelector('#request3')
                      
                        let idyuniq =  event?.currentTarget.parentElement.parentElement.children[4].id
                          event.currentTarget?.parentElement.parentElement.children[4].setAttribute('class',`request${index1}`)
                          if(idyuniq == 'request0'){
                            request1.removeAttribute('class')
                            request2.removeAttribute('class')
                            request3.removeAttribute('class')
                          }else if(idyuniq == 'request1'){
                            request0.removeAttribute('class')
                            request2.removeAttribute('class')
                            request3.removeAttribute('class')
                          }else if(idyuniq == 'request2'){
                            request1.removeAttribute('class')
                            request0.removeAttribute('class')
                            request3.removeAttribute('class')

                          }else if(idyuniq == 'request3'){
                            request1.removeAttribute('class')
                            request2.removeAttribute('class')
                            request0.removeAttribute('class')
                          }
                        
                        
                        
                        }}>Оставить заявку</button></li>
                      <ul  id="tarif_data_content_content_1">{el.inner_content.tarifes_others?.map((el,index) =>{  
                          return <li className="shex"><span>{el}</span></li>
                      })}</ul>
                    <div id={`${'request'+index1}`}>
                <div className="registrationpaid">
                   <div className="fon"> 
                   <span id="request_span" onClick={() => {
                             let request0 = document.querySelector('#request0')
                             let request1 = document.querySelector('#request1')
                             let request2 = document.querySelector('#request2')
                             let request3 = document.querySelector('#request3')

                             request0.removeAttribute('class')
                             request1.removeAttribute('class')
                             request2.removeAttribute('class')
                             request3.removeAttribute('class')
                       
                   }}><img src={Vector_1}/></span>
                   <div className="registrationpaidBox2">
                       <h2>Оставить заявку</h2>
                           <SignupForm />
                              </div>
                      </div>
                    </div>

                    </div> 
               </section>
              })}
          
        </div>
            
        </div>
    </div>
    )}