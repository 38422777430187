import "./App.css";
import Home from "./home/Home.js";
import Catering from "./catering/Catering.js";
import Cateringnas from "./cateringnas/Cateringnas.js"
import Navbar from "./navbar/Navbar.js";
import { Routes, Route, useLocation } from "react-router-dom";
import Retail from "./retail/Retail.js";
import Tarif from "./tarif/Tarif.js"
import Download from "./download/Download.js";
import Site from "./site/Site.js";
import Sitenas from "./sitenas/Sitenas";
import Mersi from "./mersi/Mersi.js";
import Besp from "./besp/Besp.js";
import Pro from "./pro/Pro";
import Maxs from "./maxs/Maxs";
import Maxpl from "./maxpl/Maxpl";
import Navmenu from "./navbar/Navmenu";
import Signin from "./signin/Signin.js";
import Password from "./password/Password.js";
import Reset from "./reset/Reset";
import Registration from "./registration/Registration";
import Registrationpaid from "./registrationpaid/Registrationpaid";
import Mailcod from "./mailcod/Mailcod";
import Cabinet from "./cabinet/Cabinet";
import Recovery from "./recovery/Recovery";
import Telqr from "./telqr/Telqr";
import Whqr from "./whqr/Whqr"
import TarifOwn from "./tarifOwn/TarifOwn.js";
import Bycard from "./bycard/Bycard.js";
import Transfer from "./transfer/Transfer.js";
import Cardok from "./cardok/Cardok.js";
import Transferok from "./transferok/Transferok.js";
import Detail from "./detail/Detail.js"
import { useEffect, useMemo, useState } from "react";
import Uniq from "./uniq/Uniq";
import { EmailSteate, URL } from "./store/rootReducer";
import { useNavigate } from "react-router-dom";
import Partner from "./partner/Partner";
import FAQ from "./faq/FAQ";












function App_() {
const [Intervals, setIntervals] = useState(true)
const navigate = useNavigate();
let Location = useLocation()
let GetToken = localStorage.getItem('Tokens')
let User =  localStorage.getItem( 'User')

let ScrollToTop =() => {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0,0)
  },[pathname])

  return null
}

ScrollToTop()
// useMemo(() => { 
//   if(Location.pathname){
//      if(User == 'undefined' || User == '' ||  User == null){
//       localStorage.clear()
//      }
//   }
// },[Location,User])  

// let Interval =  setInterval(async() => {
//   let RefreshToken = await localStorage.getItem('RefreshToken')    
//   let Tokens = await localStorage.getItem( 'Tokens')
//    fetch(`${URL}/refreshtoken/`,{
//     method: "POST",
//     headers: {
//       'Content-Type':  'application/json', 
//       'Authorization':` Bearer ${Tokens}`},
  
//      body:JSON.stringify({
//       refresh_token:RefreshToken
//      })
//      })
//      .then((res) => res.json())
//             .then((res) => {
//              if(res.refresh_token){
//              localStorage.setItem('RefreshToken' ,res.refresh_token)    
//              localStorage.setItem( 'Tokens', res.access_token) 
//             }else if(res.detail == 'ERROR'){
//               localStorage.clear()
//                navigate('/Signin')
//             }})                   
//     }, 50*60*10000);

  return (
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/Navbar" element={<Navbar />} />
      <Route path="/Catering" element={<Catering />} />
      <Route path="/Cateringnas" element={<Cateringnas/>}/>
      <Route path="/Retail" element={<Retail />} />
      <Route path="/Tarif" element={<Tarif />} />
      <Route path="/Download" element={<Download />} />
      <Route path="/Site" element={<Site />} />
      <Route path="/Sitenas" element={<Sitenas />} />
      <Route path="/Mersi" element={<Mersi/>} />
      {/* <Route path="/Besp" element={<Besp/>} />
      <Route path="/Pro" element={<Pro/>} />
      <Route path="/Maxs" element={<Maxs/>} />
      <Route path="/Maxpl" element={<Maxpl/>} /> */}
      <Route path="/Navmenu" element={<Navmenu />} />
      <Route path="/Signin" element={<Signin Intervals ={Intervals} />} />
      {/* <Route path="/Recovery" element={<Recovery/>} />
      <Route path="/Password" element={<Password/>} /> */}
      <Route path="/Reset" element={<Reset/>} />
      {/* <Route path="/Registration" element={<Registration/>} />
      <Route path="/Registrationpaid" element={<Registrationpaid/>} /> */}
      <Route path="/Mailcod" element={<Mailcod/>} />
      <Route path="/Cabinet" element={<Cabinet/>} />
      <Route path="/Telqr" element={<Telqr/>} />
      <Route path="/Whqr" element={<Whqr/>}/>
      <Route path="/TarifOwn" element={<TarifOwn/>}/>
      {/* <Route path="/Bycard" element={<Bycard/>}/> */}
      <Route path="/Transfer" element={<Transfer/>}/>
      <Route path="/Cardok" element={<Cardok/>}/>
      <Route path="/Transferok" element={<Transferok/>}/>
      <Route path="/Detail" element={<Detail/>}/>
      <Route path="/Uniq" element={<Uniq/>}/>
      <Route path="/Partner" element={<Partner/>}/>
      {/* <Route path="/FAQ" element={<FAQ/>}/> */}
      

      

    </Routes>
  );
  
  
}

export default App_;