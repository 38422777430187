import "./Transfer.css";
import Navbar from "../navbar/Navbar";
import Visa from "./transfer_img/Visa.svg";
import Master from "./transfer_img/Master.svg";
import Amer from "./transfer_img/Amer.svg";
import Chg from "./transfer_img/Chg.svg";
import Vect from "./transfer_img/Vect.svg";
import { useNavigate } from "react-router-dom";
import {  Summ , SummId } from "../store/globalstate";
import { useSelector } from "react-redux";
import { Token } from "../store/globalstate";
import { Detaile } from "../store/globalstate"
import TarifOwn from "../tarifOwn/TarifOwn";
import { Counterne} from "../store/globalstate";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Tokenn } from "../store/rootReducer";



export default function  Transfer(props) {
    let RefreshTokens=localStorage.getItem("RefreshToken")
    let  Tokens =localStorage.getItem("Tokens")
    const Counter = useSelector(Counterne)
    const navigate = useNavigate()
    const Token_bild = useSelector(Token)
    const [transferok,settransferok] = useState(false)
    const Detaill = useSelector(Detaile)
    const Summs = useSelector(Summ)
    const SummsId = useSelector(SummId)
    let sumar = ''
    let mobile_cass=''
    let web_manager=''
    let mobile_manager=''
    let cass_stantion=''
   
    
     props.tarif.map((el) => {
         if(props.tarifner == 'pro' && el.tarif_id == 2){
           sumar = el.tarif_month_prices
           mobile_cass=el.mobile_cassa_counts
           web_manager=el.web_counts
           mobile_manager=el.manager_counts
           cass_stantion=el.cassa_counts
    }else if(props.tarifner == 'max' && el.tarif_id == 3){
        sumar = el.tarif_month_prices
        mobile_cass=el.mobile_cassa_counts
        web_manager=el.web_counts
        mobile_manager=el.manager_counts
        cass_stantion=el.cassa_counts
    }else if(props.tarifner == 'maxpl' && el.tarif_id == 4){
        sumar = el.tarif_month_prices
        mobile_cass=el.mobile_cassa_counts
        web_manager=el.web_counts
        mobile_manager=el.manager_counts
        cass_stantion=el.cassa_counts
    }
     })

   

    return(
        <>
        <Navbar />
        <div className="Transfer">
            <div className="TransferBox">
                <div className="TransferBoxS">
                <div className="TransferBox2" onClick={() => props.settransfer(false)}>
                    <div className="TransferBox1">
                    <h2> Credit/Debit Card</h2>
                    </div>
                    <div className="TransferBox1img" >
                     <img src={Visa} />
                     <img src={Master} />
                     <img src={Amer} />
                     <img src={Chg} />
                     </div>
                      </div>
                <div className="TransferBox3" onClick={()=>props.settransfer(true)}>
                     
                    <div className="TransferBox4" >
                    <h2>Transfer</h2>
                    </div>
                     <img src={Vect} />
                     </div>
                     </div>
                     <div className="TransferBox5">
                        <h2>Вы уверены, что хотите оплатить банковским переводом {SummsId == 1  ? Summs : sumar || Summs}  рубл ?</h2>
                        </div>
                        <div className="TransferBox6">
                    
                    <div className="TransferButton2">
                    <button
                    onClick={()=>{
                        fetch(`${URL}/buybytransfer/`,{
                        method: 'POST',
                        headers:{
                            'Content-Type':  'application/json', 
                           'Authorization':` Bearer ${Tokens}`}, 
                        body:JSON.stringify({ 
                            order_summ: SummsId == 1  ? Summs : sumar || Summs,
                            cass_stantion_count:Counter.cass_stantion_count || cass_stantion ,
                            mobile_cass_count:Counter.mobile_cass_count || mobile_cass ,
                            mobile_manager_count:Counter.mobile_manager_count || mobile_manager,
                            web_manager_count:Counter.web_manager_count  ||web_manager,
                            client_token: "string",
                            interval: 1

                        }),
                        })
                        .then((response) =>response.json())
                        .then((data)=>{
                            
                            if(data.status =="ok"){
                                navigate('/Transferok')
                            //    props.settransferok(true)
                            }
                        })
                    }}
                      type="button">Оплатить</button>
                    </div>
                    <div className="TransferButton1">
                    <button type="button" onClick={() => props.settransfer(false)}>Закрыть</button>
                    </div>
                </div>

                     </div>
                     
                     </div>
                     </>
                     )
                     }