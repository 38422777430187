import "./Mailcod.css";
import { Link } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import Girl1 from "../mailcod/mailcod_img/Girl1.png";
import Vecto from "../mailcod/mailcod_img/Vecto.svg";
import Mail from "../mailcod/mailcod_img/Mail.svg"
import Gmail from "../mailcod/mailcod_img/Gmail.svg"

export default function Mailcod() {
    return (
        <>
        <Navbar />
        <div className="mailcod">
    <div className="mailcodBox">
        <div className="mailcodBox3">
            <div className="mailcodBox2img1">
         <img src={Vecto}/>
         <h2>Важно</h2>
         </div>
            <div className="mailcodBox1">
            <h3>На вашу почту высланa ссылка для подтверждения аккаунта</h3>
        </div>
        <div className="mailcodBox2">
            
     
     </div>
        </div>
         <div className="mail123">
            <div className="mail">
            <a target={"_blank"} href="https://mail.ru/"><img src={Mail} /></a>
            </div>
            <div className="gmail">
            <a target={"_blank"} href="https://mail.google.com/"><img id="Gmail" src={Gmail} /></a>
            </div>
         </div>
    </div>  
   
    <div className="mailcodimg">
    <img src={Girl1} />
    </div>
      
</div>
        </>
    )
}