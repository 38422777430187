import { Link } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import Endpart from "../endpart/Endpart";
import Footer from "../footer/Footer";
import axios from "axios";
import "./Site.css";
import Cote from "../site/site_img/Cote.png";
import Telll from "../site/site_img/Telll.png";
import Fml from "../site/site_img/Fml.png";
import Clo from "../site/site_img/Clo.png";
export default function Site() {
    return (
      <>
        <Navbar />
        <div className="site">
            <div className= "siteBox1">
              <div className="siteBox12">
                <div className="siteBox1text">
                    <h1>Хотите получить сайт и приложение
                         доставки в вашем фирменном стиле и с дополнительным функционалом?</h1>
                    <p>Разработаем с нуля или интегрируем уже имеющиеся сайт и приложения с Pcassa</p>

                </div>
                <div className="siteBox1img">
                <img src={Cote} />
                </div>
            </div>
            </div>
            <div className="siteBox2">
              
                <div className="siteBox2text">
                    <h1>Ваше меню всегда будет под рукой у клиента</h1>
                    <div className="siteBox2textlist">
                    <div className="siteBox2textlist1">
                     <ul>
                        <li>Персональный дизайн и фирменный контент</li>
                        <li>Контроль всех заказов и их доставки</li>
                        <li>Карта ресторанов и зон доставки</li>
                     </ul>
                     </div>
                     <div className="siteBox2textlist2">
                     <ul>
                     <li>Модификатор товаров</li>
                     <li>Круглосуточная поддержка, которая поможет с настройкой и ответит на все вопросы</li>
                     </ul>
                     </div>
                    </div>

                </div>
                <div className="siteBox2img">
                <img src={Telll} />
                </div>
            </div>
            <div className="siteBox3">
                <div className="siteBox3text">
                    <h1>Сделайте так, чтобы ваши посетители возвращались</h1>
                    <div className="siteBox3textlist">
                    <div className="siteBox3textlist1">
                     <ul>
                        <li>Настройка акций и спецпредложений</li>
                        <li>Избранные товары, личные предпочтения каждого клиента</li>
                        
                     </ul>
                     </div>
                     <div className="siteBox3textlist2">
                     <ul>
                     <li> Система сбора данных о клиентах и интеграции программы лояльности</li>
                     <li>API, которые удобно интегрировать с любыми сторонними сервисами.</li>
                     </ul>
                     </div>
                    </div>

                </div>
                <div className="siteBox3img">
                <img src={Fml} />
                </div>
            </div>
            {/* <div className="siteBox4">
                <div className="siteBox4text">
                    <h1>Получите расчет стоимости разработки сайта и приложения в вашем фирменном стиле</h1>
                </div>
                <div className="siteBox4bi">
                   
                    <div className="siteBox4button">
                           <form>
                         <input type="tel" placeholder="   +7 999 555 35-35"></input>
                           </form>
                        <button type="button">Перезвоните мне</button>
                </div> 
                <div className="siteBox4img">
                    <img src={Clo} />
                    </div>
            </div>
          </div> */}
          
        </div>
        <Endpart/>
      <Footer />
    </>
  );
}