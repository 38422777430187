export const Token = (state) => state.Token.Token;
export const Email = (state) => state.Email.Email;
export const Nav = (state) => state.Nav.Nav;
export const Summ =(state) => state.Summ.Summ;
export const SummId =(state) => state.Summ.SummId;
export const Detaile = (state)=> state.Detail.Detail;
export const Detaile_id = (state)=> state.Detail.Detail_id;
export const Counterne = (state)=> state.Counterner.Counterner;
export const Tarif_a   = (state) => state.Tarif.tarif;
export const Wallet   = (state) => state.Wallet.Wallet;
