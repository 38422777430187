import "./Endpart.css";
import Girl from "../endpart/endpart_img/Girl.png";
import { Link } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { useNavigate } from "react-router-dom";
import { URL } from "../store/rootReducer";
import  { useState } from 'react';


 function Endpart() {
  const navigate = useNavigate();
  const [error, setError] = useState('');
 
  
 

  async function Main_Endpart(values){
   
    if (values.phone) {
      let att = values.phone.toString().split('');
      for (let i = 0; i < att.length; i++) {
        try {
          if (att[i] == '+' ) { 
            if(att[0] == '+'){
              
            }else{
              throw new Error("Invalid character '-' found");
            }

           
            
          }
         
        } catch (error) {
          
        }
      }
      console.log(att);
    }

    fetch(`${URL}/requestcompany/`, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone:values.phone 
                          }),
    })
     .then((response) => response.json())
     .then((data) => {
       if (data.status =='ok'){console.log(data);
        navigate("/Mersi")
       }
     },
     )
     .catch((error) => {
      console.error('Error:', error);
    });
   
  }
  const EndpartForm = () => {
    const [errorMessage, setErrorMessage] = useState('');
  
    const formik = useFormik({
      initialValues: {
        phone: '',
      },
      
      validationSchema:Yup.object().shape({
        phone: Yup.string().min(11,'Отсутствие сиволов').required('Отсутствие сиволов'),

      }),
      onSubmit: values => {
        Main_Endpart(values);
      },
    });
  
    const handlePhoneChange = event => {
      const phoneValue = event.target.value;
      const sanitizedPhone = phoneValue.replace(/[^+\d]/g, ''); // Remove non-digit characters except '+'
    
      if (sanitizedPhone.startsWith('+') && sanitizedPhone.indexOf('+', 1) === -1) {
        setErrorMessage('');
        formik.setFieldValue('phone', sanitizedPhone);
      } else {
        
      }
    };
  
    return (
      <div className="endpartBox2">
        <h1>Остались вопросы?</h1>
        <p>Оставьте свой номер и наш менеджер вас проконсультирует</p>
  
        <form onSubmit={formik.handleSubmit}>
          <div className="EndpartInput">
            <label htmlFor="phone">Ваше номер телефона</label>
            <input
              placeholder="+7 977 351 93-93"
              id="phone"
              name="phone"
              type="text"
              onChange={handlePhoneChange}
              value={formik.values.phone}
            />
                {error && <div style={{ color: 'red' }}>{error}</div>}
                 {formik.touched.phone && formik.errors.phone? (
             <div className="basd"><span>{formik.errors.phone}</span></div>
            ) : null}
            {errorMessage && <div className="basd"><span>{errorMessage}</span></div>}
          </div>
  
          <button type="submit">Перезвоните мне</button>
        </form>
      </div>
    );
  };
  return (
    <div className="endpart">
      <div className="endpartBox1">
        <img src={Girl} />
      </div>
      <EndpartForm/>
    </div>
  );
}

export default Endpart;
