import "./Navbar.css";
import Logo from "./navbarsvg/Logo.svg";
import { Link, useLocation } from 'react-router-dom';
import buttons from "./navbarsvg/button.svg";
import wh from "./navbarsvg/wh.svg";
import tel from "./navbarsvg/tel.svg";
import smm from "./navbarsvg/smm.svg";
import User from "./navbarsvg/user.svg";
import { useEffect, useMemo, useState } from "react";
import axios from 'axios'
import React from 'react';
 import { useFormik } from 'formik';
 import * as Yup from 'yup'
 import { useNavigate } from "react-router-dom";
 import {useDispatch, useSelector} from 'react-redux'
import { Token } from "../store/globalstate";
import { Nav } from "../store/globalstate";
import { setNav, Tokenn, URL } from "../store/rootReducer";





export default function Navbar(props) {

  let  Tokens =localStorage.getItem("Tokens")
const [nav,setnav] = useState('')
let token_live = useSelector(Token)
let Nav_data = useSelector(Nav)
const navigate = useNavigate();
const dispatch= useDispatch();
const location = useLocation();


let User =  localStorage.getItem( 'User')
               
if(User == 'undefined'||User == ''){
  localStorage.clear() 
}

function deletCookie() {
  delete(document.cookie)
}
let GetToken = localStorage.getItem('Tokens')
let GetSignin = localStorage.getItem('RefreshToken')
let GetUser = localStorage.getItem('User') 
const words = document.querySelectorAll('.word');

// Add click event listener to each word element
words.forEach(word => {
  word.addEventListener('click', () => {
    // Remove the "active" class from all words
    words.forEach(w => w.classList.remove('active'));
    // Add the "active" class to the clicked word
    word.classList.add('active');
  });
});
return (
  <nav>
    <div className="navbar_nav">
      <div className="ynd">
        <div className="navOne">
          <Link to="/">
            <img src={Logo} alt='logo' />
          </Link>
        </div>
        <div className="navTwo">
          <ul className="navTwo_itme" id="word-container">
            <Link to="/Retail">
              <li className={location.pathname === '/Retail' ? 'word active' : 'word'}>
                <a href="/Retail">Ритейл</a>
              </li>
            </Link>
            <Link to="/Tarif">
              <li className={location.pathname === '/Tarif' ? 'word active' : 'word'}>
                <a href="/Tarif">Тарифы</a>
              </li>
            </Link>
            <Link to="/Site">
              <li className={location.pathname === '/Site' ? 'word active' : 'word'}>
                <a href="/site">Сайт и приложение</a>
              </li>
            </Link>
            <Link to="/Partner">
              <li className={location.pathname === '/Partner' ? 'word active' : 'word'}>
              <a href="/Partner">Стать партнером</a>
              </li>
            </Link>
            <div className="navThree1">
           <Link to ="/Tarif">
            <button type="button" >Попробовать </button>
         </Link>
        </div>    
        <div className="anpetq">{/* <Regimemo /> */}</div>
         <div className="mnb">
          <h6>Свяжитесь с нами</h6>
          <div className="tex">
          <a target={"_blank"} href="mailto:support@pcassa.ru"><img src={smm} /></a>
          <a target={"_blank"} href="https://wa.me/message/MCB7KLMWIF4LO1?src=qr"> <img src={wh} /></a>
          <a target={"_blank"} href="https://t.me/Pcassa_pos"><img src={tel} /></a>
          
          </div>
         </div>
            {/* Other links */}
          </ul>
        </div>
        <div className="erku">
        <div className="navThree"  >
          <Link  to ="/Tarif">
          <button type="button">Попробовать </button>
          </Link>
        </div>
        <div className="navFour">
         {/* <Regimemo /> */}
        </div>
        </div>
        </div>
        <div className="mnb1">
          <h6>Свяжитесь с нами</h6>
          <div className="tex1">
          <a target={"_blank"} href="mailto:support@pcassa.ru"><img src={smm} /></a>
          <a target={"_blank"} href="https://wa.me/message/MCB7KLMWIF4LO1?src=qr"><img src={wh} /></a>
          <a target={"_blank"} href="https://t.me/Pcassa_pos"><img src={tel} /></a>
          </div>
         </div>
       
        <div className="twolines"  onClick={() => {
            let navTwo_itme = document.getElementsByClassName("navTwo_itme")[0]
            if(navTwo_itme.hasAttribute('id')){
               navTwo_itme.removeAttribute("id");
            }else{
              navTwo_itme.setAttribute("id", "twolines" );
            }
            let navThree = document.getElementsByClassName("navThree")[0]
            if(navThree.hasAttribute('id')){
              navThree.removeAttribute("id");
            }else{
              navThree.setAttribute("id", "navThree" );
            }


        }}>
          <a>
            <img src={buttons } />
          </a>
        </div>       
    </div>
  </nav>
);
  // return (
  //   <nav>
  //     <div className="navbar_nav">
  //     <div className="ynd">
  //       <div class="navOne">
  //         <Link to="/">
  //           <img src={Logo} alt='logo' />
  //        </Link>
  //       </div>
  //       <div className="navTwo">
  //         <ul className="navTwo_itme"  id="word-container">
  //         {/* <Link to="/Cateringnas">
  //           <li>
  //             <a href="Catering">Общепит</a>
  //           </li>
  //           </Link> */}
  //           <Link to="/Retail">
  //           <li class="word">
  //             <a href="/Retail">Ритейл</a>
  //           </li>
  //           </Link>
  //           <Link to="/Tarif">
  //           <li class="word">
  //             <a href="/Tarif">Тарифы</a>
  //           </li>
  //           </Link>
  //           {/* <Link to="/Download">
  //           <li>
  //             <a href="/Download">Скачать</a>
  //           </li>
  //           </Link> */}
  //           <Link to="/Site">
  //           <li class="word">
  //             <a href="/site">Сайт и приложение</a>
  //           </li>
  //           </Link>
  //           <Link to="/Partner">
  //           <li class="word">
  //             <a href="/Partner">Стать партнером</a>
  //           </li>
  //           </Link>
  //           {/* <Link to="/FAQ">
  //           <li>
  //             <a href="/FAQ">ЧЗВ</a>
  //           </li>
  //           </Link> */}
  //           <div className="navThree1">
  //          <Link to ="/Tarif">
  //           <button type="button" >Попробовать </button>
  //        </Link>
  //       </div>    
  //       <div className="anpetq">{/* <Regimemo /> */}</div>
  //        <div className="mnb">
  //         <h6>Свяжитесь с нами</h6>
  //         <div className="tex">
  //         <a target={"_blank"} href="mailto:support@pcassa.ru"><img src={smm} /></a>
  //         <a target={"_blank"} href="https://wa.me/message/MCB7KLMWIF4LO1?src=qr"> <img src={wh} /></a>
  //         <a target={"_blank"} href="https://t.me/Pcassa_pos"><img src={tel} /></a>
          
  //         </div>
  //        </div>
  //         </ul>
         
  //       </div>
  //       <div className="erku">
  //       <div className="navThree"  >
  //         <Link  to ="/Tarif">
  //         <button type="button">Попробовать </button>
  //         </Link>
  //       </div>
  //       <div className="navFour">
  //        {/* <Regimemo /> */}
  //       </div>
  //       </div>
  //       </div>
  //       <div className="mnb1">
  //         <h6>Свяжитесь с нами</h6>
  //         <div className="tex1">
  //         <a target={"_blank"} href="mailto:support@pcassa.ru"><img src={smm} /></a>
  //         <a target={"_blank"} href="https://wa.me/message/MCB7KLMWIF4LO1?src=qr"><img src={wh} /></a>
  //         <a target={"_blank"} href="https://t.me/Pcassa_pos"><img src={tel} /></a>
  //         </div>
  //        </div>
       
  //       <div className="twolines"  onClick={() => {
  //           let navTwo_itme = document.getElementsByClassName("navTwo_itme")[0]
  //           if(navTwo_itme.hasAttribute('id')){
  //              navTwo_itme.removeAttribute("id");
  //           }else{
  //             navTwo_itme.setAttribute("id", "twolines" );
  //           }
  //           let navThree = document.getElementsByClassName("navThree")[0]
  //           if(navThree.hasAttribute('id')){
  //             navThree.removeAttribute("id");
  //           }else{
  //             navThree.setAttribute("id", "navThree" );
  //           }


  //       }}>
  //         <a>
  //           <img src={buttons } />
  //         </a>
  //       </div>       
  //     </div> 
  //     {/* <Navmenu /> */}
  //   </nav>
   
  // );
}


