import "./Bycard.css";
import Navbar from "../navbar/Navbar";
import Visa from "../bycard/bycard_img/Visa.svg";
import Master from "../bycard/bycard_img/Master.svg";
import Amer from "../bycard/bycard_img/Amer.svg";
import Chg from "../bycard/bycard_img/Chg.svg";
import Vect from "../bycard/bycard_img/Vect.svg";
import { useNavigate } from "react-router-dom";
import { EmailSteate, setSumm, URL } from "../store/rootReducer";
import { Summ, SummId } from "../store/globalstate";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { Counterne } from "../store/globalstate";
import { Token } from "../store/globalstate";


export default function Bycard(props) {
      const navigate = useNavigate()
    const [list, setlist] = useState({
        order_summ: '',
        cass_stantion_count: '',
        mobile_cass_count: '',
        mobile_manager_count: '',
        web_manager_count: '',
        client_token: "string",
        interval: 1
    })
    const Counter = useSelector(Counterne)
    const Token_bild = useSelector(Token)
    const Summs = useSelector(Summ)
    const SummsId = useSelector(SummId)

    let sumar = ''
    let mobile_cass = ''
    let web_manager = ''
    let mobile_manager = ''
    let cass_stantion = ''

    

    // props.tarif.map((el) => {
    //     if (props.tarifner == 'Pro' && el.tarif_id == 2) {
    //         sumar = el.tarif_month_prices
    //         mobile_cass = el.mobile_cassa_counts
    //         web_manager = el.web_counts
    //         mobile_manager = el.manager_counts
    //         cass_stantion = el.cassa_counts
    //     } else if (props.tarifner == 'Max' && el.tarif_id == 3) {
    //         sumar = el.tarif_month_prices
    //         mobile_cass = el.mobile_cassa_counts
    //         web_manager = el.web_counts
    //         mobile_manager = el.manager_counts
    //         cass_stantion = el.cassa_counts
    //     } else if (props.tarifner == 'Maxpl' && el.tarif_id == 4) {
    //         sumar = el.tarif_month_prices
    //         mobile_cass = el.mobile_cassa_counts
    //         web_manager = el.web_counts
    //         mobile_manager = el.manager_counts
    //         cass_stantion = el.cassa_counts
    //     }
    // })

    useEffect(() => {
        fetch(`${URL}gettarifesforview/ru/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json', 'charset': "utf-8",
            }
        })
            .then(el => el.json())
            .then(el => {
                el.map((el) => {
                    if (el.tarif_names == props.tarifner) {
                        setlist({
                            order_summ: el.tarif_month_prices,
                            mobile_cass_count: el.inner_content.mobile_cassa_counts,
                            web_manager_count: el.inner_content.web_counts,
                            mobile_manager_count: el.inner_content.manager_counts,
                            cass_stantion_count: el.inner_content.cassa_counts,
                            client_token: "string",
                            interval: 1
                        })
                    }
                })


            })
            .catch(err => console.error(err))

    }, [])

useEffect(() => {
    if (Counter) {
        setlist({
            order_summ: SummsId == 1 ? Summs : sumar || Summs,
            cass_stantion_count: Counter?.cass_stantion_count || cass_stantion,
            mobile_cass_count: Counter?.mobile_cass_count || mobile_cass,
            mobile_manager_count: Counter?.mobile_manager_count || mobile_manager,
            web_manager_count: Counter?.web_manager_count,
            client_token: "string",
            interval: 1
        })
    } 
} ,[Counter])

    return (
        <>
            <div className="Bycard">
                <div className="BycardBox">
                    <div className="BycardBoxS">
                        <div className="BycardBox2" onClick={() => props.setbycard(true)}>
                            <div className="BycardBox1">
                                <h2> Credit/Debit Card</h2>
                            </div>
                            <div className="BycardBox1img">
                                <img src={Visa} />
                                <img src={Master} />
                                <img src={Amer} />
                                <img src={Chg} />
                            </div>
                        </div>
                        <div className="BycardBox3" onClick={() => props.settransfer(true)}>

                            <div className="BycardBox4" >
                                <h2>Transfer</h2>
                            </div>
                            <img src={Vect} />
                        </div>
                    </div>

                    <div className="BycardBox9"><h2>You need to pay {SummsId == 1 ? Summs : list.order_summ || Summs} рубл</h2></div>

                    <div className="BycardBox8">

                        <div className="BycardButton2">
                            <button
                                onClick={() => {          
                                    let Tokens = localStorage.getItem("Tokens")
                                    let RefreshToken  = localStorage.getItem("RefreshToken")     
                                        fetch(`${URL}/buybycard`, {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': ` Bearer ${Tokens}`
                                        },
                                        body: JSON.stringify({ ...list }),
                                    })
                                        .then((response) => response.json())
                                        .then((data) => {
                                            if (data) {
                                                navigate('/Cardok')
                                            }
                                        })
                                    }                      
                                    
                                }
                                type="button">Оплатить</button>
                        </div>
                        <div className="BycardButton1">
                            <button type="button" onClick={() => props.setbycard(false)}>Закрыть</button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
