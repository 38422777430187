import "./Cardok.css";
import Vecto from "../cardok/cardok_img/Vecto.svg"
import Navbar from "../navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export default function  Cardok(props) {

    const navigate = useNavigate();
    return (
        <>
        <Navbar/>
       <div className="cardok">
        <div className="cardok1">
            <div className="cardok2">
                <div className="cardok3"> 
                 <img src={Vecto}/>
                 <h2>Важно</h2>
                 <span>Ваш запрос успешно выполнен. На вашу почту отправлена ссылка для активации тарифа. </span>
                 </div>
                 
                 
            </div>
            <div className="cardok4">
                    <p onClick={() =>navigate("/Cabinet") }>Хорошо</p>
                 </div>
        </div>
       </div>
       </>
        )}

