import "./Signin.css";
import { Link } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import { useCallback, useEffect, useState } from "react";
import axios from 'axios'
import React from 'react';
 import { useFormik } from 'formik';
 import * as Yup from 'yup'
 import { useNavigate } from "react-router-dom";
 import {useDispatch, useSelector} from 'react-redux'
import { setToken, URL,setNav } from "../store/rootReducer";
import Acq from "./signinimg/acq.svg";





export default function Signin(props) {
  const [error,seterror] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [cabinet,setcabinet] = useState('')

  

useEffect(() => {
  if(error){
    let password = document.getElementById('password')
    let email  = document.getElementById('email')
    if(email){
      email.setAttribute('class', 'email')
    }
    if(password){
          password.setAttribute('class', "password")
    }
  }
},[error])


//  async function  Main_Page_Restauranth(values){
//     try{
//         var xhr = new XMLHttpRequest();
//         xhr.open('POST', `${URL}/signin`);
//         await xhr.send(JSON.stringify({
//           username: values.email,
//           password: values.password,
//       }));
  
//       xhr.onload = async() => {
       
//       let  user = await JSON.parse(xhr.response)
//        
//       }
  
//     }catch (error) {
//           console.log(error);
//        }; 
//    }

async function  Main_Page_Restaurant(values){

        const loginForm = document.querySelector(".login-form")
        const formData = new FormData(loginForm)
        formData.append('username', values.email)
        formData.append('password', values.password)
             fetch(`${URL}/user/signin`, {
            method: "POST",
            body: formData,
          })
          .then((res) => res.json())
          .then( async(token) => {
            dispatch(setToken(token))
            if(token.access_token){
              console.log(token);
              await localStorage.setItem('RefreshToken' ,token.refresh_token)    
              await localStorage.setItem( 'Tokens', token.access_token)
             
                fetch(`${URL}/user/me?language=ru`, {
                    method: 'GET',
                    headers: {'Content-Type': 'application/json',
                    'Authorization':` Bearer ${token.access_token}`
                   }
            
                }).then((respons) => respons.json())
                .then(async(data) => {
                  if(data){
                 await   setcabinet(data)
                  await  dispatch(setNav(data))
                 await   localStorage.setItem('User',data.c_contact_name)
                  await   navigate('/Cabinet')
                  }
                })
               
            //  let Interval =  setInterval(async() => {
            //    let RefreshToken = await localStorage.getItem('RefreshToken')    
            //    let Tokens = await localStorage.getItem( 'Tokens')
            //     fetch(`${URL}/refreshtoken/`,{
            //      method: "POST",
            //      headers: {
            //        'Content-Type':  'application/json', 
            //        'Authorization':` Bearer ${Tokens}`},
               
            //       body:JSON.stringify({
            //        refresh_token:RefreshToken
            //       })
            //       })
            //       .then((res) => res.json())
            //              .then((res) => {
            //               if(res.refresh_token){
            //               localStorage.setItem('RefreshToken' ,res.refresh_token)    
            //               localStorage.setItem( 'Tokens', res.access_token) 
            //              }
            //              })                   
            //      }, 10000);
            //      Interval()
                //  if(props.Intervals == false){
                //      return  () => clearInterval(Interval)
                //  }
                 
          }else{
          seterror(true)
          }
            
          })
          .catch((err) => {
            console.log("Error", err)
          })
    }
           

    const SignupForm = () => {
      const [eys, seteys] = useState(true)
      const formik = useFormik({
        initialValues: {
          password:'',
          email: '',
        },
        validationSchema:Yup.object().shape({
          email: Yup.string().email('Invalid email').required('Email is required'),
          password: Yup.string().min(4,'Пароли не совпадают').required('Пароли не совпадают'),
        }),
        onSubmit: values => {
         Main_Page_Restaurant(values) 
        //  Main_Page_Restauranth(values)    
 
        },
      });
      return (
        <form onSubmit={formik.handleSubmit} className ='login-form'>
          <div className="signinButon1">
          <label  htmlFor="email">E-mail* </label>
          <input
            placeholder="example@gmail.com"
            id="email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            value={formik.values.email}
          />   
            <label htmlFor="password">Пароль*
            <input            
            id="password"
            name="password"
            type={eys == true ? 'password' : 'text'}       
            onChange={formik.handleChange}
            value={formik.values.password}
             />
            
             </label> <img onClick={()=>seteys(!eys)} src={Acq}  />
             {formik.touched.password && formik.errors.password ? (
             <div className="basd"><span>{formik.errors.password}</span></div>
            ) : null}
            
             <a href="/Recovery">Забыл пароль?</a>
             
          <button type="submit">Вход</button>
          </div>
          
        </form>
        
        
      );
      
     
      
    };
    return (
      <>
      <Navbar />
      
      
        <div className="signin">
            <div className="signinBox">     
            <div className="signinBox1">
                <h3>Войти в аккаунт</h3>
               {error && <div className="signineror">Неправильный адрес почты или пароль</div>}
                  <SignupForm />
            </div>
          
               <div className="signinButon2">
          <Link  to="/Registrationpaid">
            <button type="button">Регистрация</button>
          </Link>
              </div>
              <div className="res">
      </div>
        </div>
        </div>
        </>
     );
}

