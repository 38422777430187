import "./Footer.css";
import Logo from "./footer_img/Logo.svg";
import Telegram from "./footer_img/telegram.svg";
import Whatsapp from "./footer_img/whatsapp.svg";
import telef from "./footer_img/telef.svg";
import mesig from "./footer_img/mesig.svg";
import gala from "./footer_img/gala.svg";
import Tqr from "./footer_img/Tqr.svg";
import Wqr from "./footer_img/Wqr.svg";
import Vector from "./footer_img/Vector.svg";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footerBox">
      
     
      <div className="footerBox2">
        <div>
          <img src={Vector} /> 
          <div className="footerBox1">
        {/* <img src={Logo} /> */}
        <a target={"_blank"} href="https://pcassa.ru/privacy_policy/privacy/privacy.html">Политика конфиденциальности</a>
        <p>2023</p>
      </div>
        </div>
        {/* <div className="footerBox22">
          <div className="smms"><img src={mesig} /> <p><a > info@pcassa.ru</a> </p></div>
          <div className="her"><img src={telef} /> <p>+79773519393</p></div>
          <div className="hasce"><img src={gala} /> <p>Адрес</p></div>
        </div> */}
       </div> 
       <div className="quar">
      <img src={Tqr} />
      <img src={Wqr} />
      </div>
       <div className="info" style={{width:'35%'}}>
        <div><p>Адрес:</p> <a>125481,г.Москва, ул Свободы,дом. 99, кор. 1,Армения, Ереван, Аван, ул. Худякова 68, кв. 50</a></div>
        <div><p>Техническая подержка:</p> <a target={"_blank"} href="mailto:support@pcassa.ru">support@pcassa.ru</a></div>
        <div><p>Отдел продаж:</p> <a target={"_blank"} href="mailto:sales@pcassa.ru">sales@pcassa.ru </a></div>
        <div><p>Отдел по работе с партнёрами:</p> <a target={"_blank"} href="mailto:partner@pcassa.ru">partner@pcassa.ru</a></div>
       </div>
    </div>
  );
}

export default Footer;
