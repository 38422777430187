import "./Whqr.css";
import { Link } from "react-router-dom";
import  Wha from "./whqr_img/wha.png"

export default function Whqr() {
    return(
        <div className="whqr">
            
            <Link>
            <img src={Wha} />
            </Link>
        </div>
    )
}