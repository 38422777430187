import "./Detail.css";
import {Detaile} from "../store/globalstate"
import {Detaile_id} from "../store/globalstate"
import { useSelector } from "react-redux";
import Navbar from "../navbar/Navbar";
import { useCallback, useEffect, useState } from "react";
import { setDetail, URL} from "../store/rootReducer";
import { Token } from "../store/globalstate";


export default function Detail(props) {
    let RefreshTokens=localStorage.getItem("RefreshToken")
    let  Tokens =localStorage.getItem("Tokens")
    const Detaill = useSelector(Detaile)
    const Detaill_id = useSelector(Detaile_id)
    const Token_bild = useSelector(Token)
    const [detail,setdetail] = useState('')
    

    useEffect(() => {
        fetch(`${URL}/signin/?language=ru`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json',
            'Authorization':` Bearer ${Tokens}`
           }
    
        }).then((respons) => respons.json())
        .then((data) => {
          if(data){
            setdetail(data)
            
            
    
          }
        })
    },[Token_bild])
    let Detail_name = useCallback(()=>{
        return <div>
            <div>
                {detail?.tarif_list?.map((element,index)=>{
                if(element.t_id == Detaill_id){
                     return <div>
                        {element.t_name}
                    </div>
                }
                   
                })}
            </div>
        </div>
    })
    let Detail_data = useCallback(()=>{
        return <div>
            <div>
                {detail?.tarif_list?.map((element,index)=>{
                    if(element.t_id == Detaill_id){
                        return <div>
                        {element.end_license}
                    </div>
                    }
                    
                })}
            </div>
        </div>
    })


  

    return(
        <>
        <Navbar/>
       
        <div className="detail">
            <div className="detailBox1">
                <h2>Подробно</h2>
                <p></p>
            </div>
            <div className="detailBox2">
                <div className="detailBox21">
                    <h4>Ваш тариф</h4>
                    <Detail_name/>
                  
                    
                </div>
                <div className="detailBox22">
                <h4>Информация</h4>
                <ul>
                    <li>Кассовая станция-{Detaill.cass_stantion_c}</li>
                    <li>Мобильная касса-{Detaill.mobile_cassa_c}</li>
                    <li>Мобиьный менеджер-{Detaill.mobile_manager_c}</li>
                    <li>WEB менеджер-{Detaill.web_manager_c}</li>
                </ul>
                
                </div>
                <div className="detailBox23">
                <h4>Крайний срок</h4>
                <Detail_data />
                <button type="submit">Оплатить</button>
                </div>
            </div>
        </div>
         </>
    )
}