import "./Reset.css";
import { Link } from "react-router-dom";
import Vector from "../reset/reset_img/Vector.png";
import Navbar from "../navbar/Navbar";

export default function Reset() {
    return (
        <>
        <Navbar />
<div className="reset">
    <div className="resetBox">
        <div className="resetBox1">
            <h3>Пароль востановлен</h3>
        </div>
        <div className="resetBox2">
         <img src={Vector}/>
         </div>
    </div>
</div>
</>
    )
}