import { Link } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import Endpart from "../endpart/Endpart";
import Footer from "../footer/Footer";
import axios from "axios";
import "./Catering.css";
import CateringBox1 from "../catering/catering_img/CateringBox1.png";
import Windows from "../catering/catering_img/Windows.svg";
import Android from "../catering/catering_img/Android.svg";
import Apple from "../catering/catering_img/Apple.svg";
import Linux from "../catering/catering_img/Linux.svg";
import HomeBox2 from "../catering/catering_img/HomeBox2.svg";
import Caption1 from "../catering/catering_img/Caption1.png";
import Caption2 from "../catering/catering_img/Caption2.png";
import Caption3 from "../catering/catering_img/Caption3.png";
import Caption4 from "../catering/catering_img/Caption4.png";
import Caption5 from "../catering/catering_img/Caption5.png";
import Caption6 from "../catering/catering_img/Caption6.png";
import Caption7 from "../catering/catering_img/Caption7.png";
import Caption8 from "../catering/catering_img/Caption8.png";
import Finger from "../catering/catering_img/Finger.svg";
import Waiter from "../catering/catering_img/Waiter.png";
import Hand from "../catering/catering_img/Hand.png";
import Gift from "../catering/catering_img/Gift.png";
import Shut from "../catering/catering_img/Shut.png";
import Grafik from "../catering/catering_img/Grafik.png";
import Deliver from "../catering/catering_img/Deliver.png";
import Layers2 from "../catering/catering_img/Layers2.png";
import Vecctor from "../catering/catering_img/Vecctor.svg";
import Burg from "../catering/catering_img/Burg.png";

export default function Catering() {
  return (
    <>
      <Navbar />
      <div className="catering">
        <div className="cateringBox1">
          <div className="cateringBox1text">
            <h1>Pcassa - все,что нужно для автоматизации вашего заведения</h1>
          </div>
          <div className="cateringBox1img">
            <img src={CateringBox1} />
          </div>
        </div>
        <div className="cateringBox2">
          <div className="cateringBox2text">
            <h1>
              Автоматизация бизнеса по вашим правилам и без лишних проблем
            </h1>
            <p>
              Вам не нужно покупать дорогие сервера и специальные кассы, всю
              автоматизацию можно настроить на любом устройстве и операционной
              системе
            </p>
            <div className="programss">
              <img src={Windows} />
              <img src={Android} />
              <img src={Apple} />
              <img src={Linux} />
            </div>
          </div>
          <div className="img2">
            <img src={HomeBox2}  />
          </div>
        </div>
        <div className="cateringBox3">
          <div className="cateringBox3ti">
            <h1>Подойдет для любого формата</h1>
            <img src={Finger} />
          </div>
          <div className="cateringBox3img">
            <div className="zxc"></div>
            <div className="cateringBox3img1">
              <span>
                <img src={Caption4} />
                <h6>Ресторан</h6>
              </span>
              <span>
                <img src={Caption2} />
                <h6>Кафе</h6>
              </span>
              <span>
                <img src={Caption3} />
                <h6>Бар</h6>
              </span>
              <span>
                <img src={Caption1} />
                <h6>Дарк китчен</h6>
              </span>
            </div>

            <div className="cateringBox3img2">
              <span>
                <img src={Caption5} />
                <h6>Кофеточка</h6>
              </span>
              <span>
                <img src={Caption6} />
                <h6>Столовая</h6>
              </span>
              <span>
                <img src={Caption7} />
                <h6>Кальянная</h6>
              </span>
              <span>
                <img src={Caption8} />
                <h6>Фастфуд (точка на фудкорте)</h6>
              </span>
            </div>
          </div>
          <div className="cateringBox3Button3">
            <a>
              <button type="button">Мобильное меню</button>
            </a>
          </div>
        </div>
        <div className="cateringBox4">
          <div>
            <div className="cateringBox4ti">
              <h1>Ускорьте обслуживание до 3 раз</h1>
              <p>
                Установите приложение Pcassa на смартфоны официантов и
                автоматизируйте прием заказов
              </p>
            </div>
            <div className="cateringBox4list">
              <ul>
                <li>
                  Кассовый терминал можно установить на любое устройство -
                  планшеты, ноутбуки, моноблоки, смартфоны
                </li>
                <li>Все заказы с кассы моментально уходят на кухню</li>
                <li>
                  Интерфейс кассы ориентирован на скорость обслуживания и его
                  можно настроить под себя
                </li>
              </ul>
            </div>
          </div>
          <div className="cateringBox4img">
            <img src={Waiter} />
          </div>
        </div>
        <div className="cateringBox4Button4">
          <a>
            <button type="button">Мобильный менеджер</button>
          </a>
        </div>
        <div className="cateringBox5">
          <div className="cateringBox5m">
            <div className="cateringBox5ti">
              <h1>Контролируйте работу заведения со своего смартфона</h1>
              <p>
                Следите за посещаемостью, продажами, работой персонала,
                получайте регулярные отчеты с полной аналитикой по заведению
              </p>
            </div>
            <div className="cateringBox5list">
              <ul>
                <li>Анализируйте и сравнивайте основные показатели бизнеса</li>
                <li>
                  Контролируйте остатки на складе и получайте напоминания о
                  закупках
                </li>
                <li>Составляйте тех-карты и проводите инвентаризацию</li>
                <li>
                  Настраивайте уровни доступа к управлению для сотрудников
                </li>
              </ul>
            </div>
          </div>
          <div className="cateringBox5img">
            <img src={Hand} />
          </div>
        </div>
        <div className="cateringBox6">
          <div className="cateringBox6tb">
            <div className="cateringBox6ti">
              <h1>Попробуйте Pcassa бесплатно</h1>
              <p>
                Не платите за автоматизацию пока в вашем меню до 20-ти позиций
              </p>
              <div className="cateringBox6Button6">
                <a>
                  <button type="button">Получить Pсassa</button>
                </a>
              </div>
            </div>

            <div className="cateringBox6img">
              <img src={Gift} />
            </div>
          </div>
        </div>
        <div className="cateringBox6Button61">
          <a>
            <button type="button">Управление персоналом</button>
          </a>
        </div>
        <div className="cateringBox7">
          <div>
            <div className="cateringBox7ti">
              <h1>Следите за работой персонала</h1>
              <p>
                Будьте в курсе всех действий сотрудников, отслеживайте
                эффективность работы и предотвращайте кражи
              </p>
            </div>
            <div className="cateringBox7list">
              <ul>
                <li>
                  Просматривайте историю действий сотрудника и отчет по каждому
                  чеку
                </li>
                <li>Получайте уведомления о сомнительных операциях</li>
                <li>Узнавайте кто из сотрудников работает эффективнее</li>
              </ul>
            </div>
          </div>
          <div className="cateringBox7img">
            <img src={Shut} />
          </div>
        </div>
        <div className="cateringBox7Button7">
          <a>
            <button type="button">Полная отчетность</button>
          </a>
        </div>
        <div className="cateringBox8">
          <div className="cateringBox8m">
            <div className="cateringBox8ti">
              <h1>
                Получайте регулярные подробные отчеты обо всём что происходит в
                бизнесе
              </h1>
              <p>
                Контролируйте все процессы, отслеживайте динамику продаж,
                эффективность сотрудников, прогнозируйте посещаемость и нагрузку
              </p>
            </div>
            <div className="cateringBox8list">
              <ul>
                <li>
                  Используйте стандартные шаблоны отчетов или создавайте
                  собственные
                </li>
                <li>
                  Отслеживайте какие позиции прибыльнее, а сотрудники
                  эффективнее
                </li>
                <li>Контролируйте все доходы и расходы</li>
              </ul>
            </div>
          </div>
          <div className="cateringBox8img">
            <img src={Grafik} />
            <p>Удобный Dashboard, который можно настроить под себя</p>
          </div>
          <div className="burg"> <img src={Burg} /></div>
        </div>
        <div className="cateringBox8Button8">
          <a>
            <button type="button">Управление персоналом</button>
          </a>
        </div>
        <div className="cateringBox9">
          <div className="cateringBox9m">
            <div className="cateringBox9ti">
              <h1>Автоматизируйте доставку</h1>
              <p>
                Принимайте заказы с любых каналов, отслеживайте этапы доставки и
                получайте аналитику по каждому заказу
              </p>
              <div className="cateringBox9list">
                <ul>
                  <li>
                    Удобная интеграция с Delivery Club, Яндекс едой,
                    телеграм-ботами и телефонией
                  </li>
                  <li>Полный контроль над заказами из системы</li>
                  <li>
                    Создание приложения и сайта для заказа в фирменном стиле
                  </li>
                </ul>
              </div>
              <div className="cateringBox9Button9">
                  
                 <a href="/Tarif">Узнать подробнее</a>
                 <a href="/Tarif"> <img src={Vecctor} /></a>
                

                
              </div>
            </div>

            <div className="cateringBox9img">
              <img src={Deliver} />
            </div>
          </div>
        </div>

        <div className="cateringBox10">
          <div className="cateringBox10tb">
            <div className="cateringBox10ti">
              <h1>Оцените удобство Pcassa прямо сейчас</h1>
              <p>
                Просто зарегистрируйтесь в системе, настроить автоматизацию
                можно даже на личном устройстве
              </p>
              <div className="cateringBox10Button10">
                <a>
                  <button type="button">Получить Pсassa</button>
                </a>
              </div>
            </div>

            <div className="cateringBox10img">
              <img src={Layers2} />
            </div>
          </div>
        </div>
      </div>
      <Endpart />
      <Footer />
    </>
  );
}
