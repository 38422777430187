
import Navbar from "../navbar/Navbar";
import Endpart from "../endpart/Endpart";
import Footer from "../footer/Footer";
import axios from "axios";
import "./Tarif.css";
import Girl from "../tarif/tarif_img/Girl.png"
import Layers2 from "../tarif/tarif_img/Layers2.png"
import Tarifimg1 from "../tarif/tarif_img/Tarifimg1.png"
import Tarifimg2 from "../tarif/tarif_img/Tarifimg2.png"
import Tarifimg3 from "../tarif/tarif_img/Tarifimg3.png"
import { useCallback, useEffect, useMemo, useState } from "react";
import Besp from '../besp/Besp'
import Pro from '../pro/Pro'
import Maxs from '../maxs/Maxs'
import Maxpl from '../maxpl/Maxpl'
import TarifOwn from '../tarifOwn/TarifOwn'
import { Token } from "../store/globalstate";
import { useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux'
import Bycard from "../bycard/Bycard";
import Transfer from "../transfer/Transfer";
import Transferok from "../transferok/Transferok"
import { URL } from "../store/rootReducer";
import { setSumm, setSummId, Tokenn } from "../store/rootReducer";
import Cardok from "../cardok/Cardok";




export default function  Tarif(props) {
  let RefreshTokens=localStorage.getItem("RefreshToken")
  let  Tokens =localStorage.getItem("Tokens")
  const [value,setvalue] = useState(false)
  const [pro,setpro] = useState(false)
  const [maxs,setmaxs] = useState(false)
  const [maxpl,setmaxpl] = useState(false)
  const [tarifOwn,settarifOwn] = useState(true)
  const [transfer,settransfer] = useState(false)
  const [transferok,settransferok] = useState(false)
  const [cardok,setcardok] = useState(false)
  const [bycard,setbycard] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const Token_bild = useSelector(Token)
  const[tarif,settarif] =useState('')
  const [tarifner,settarifner] = useState('')
 
  useEffect(()=> {
    fetch(`${URL}/gettarifesforview/ru`,{
      method: 'GET',
      headers: {'Content-Type': 'application/json','charset':"utf-8",
    }
    })
                .then(response => response.json())
                .then(response => {
                  if(response){
                   settarif(response)

                  }
                })
                .catch(err => console.error(err))
  },[])
  


//  let Tarif_data = useCallback(()=>{
// if(tarif){
//   return <div>
//     {tarif.map((elem,index)=> {
//      if(elem.tarif_id==1){
//     return <ul key={index}>
//       {elem   }
//     </ul>
//  }})}

 
//   </div>
  
// }
//  },[tarif])

//  let Tarif_data = useCallback(()=>{
//   if(tarif){
//     return <div>
      
//       {tarif.map((elem, index)=>{
//         if(elem.tarif_id==1){ 
//          return <ul>
//          <li>{elem.inner_content.cassa_names}-{elem.inner_content.cassa_counts}</li>
//          <li>{elem.inner_content.manager_names}-{elem.inner_content.manager_counts}</li>
//          <li>{elem.inner_content.web_names}-{elem.inner_content.web_counts}</li>
//          <li>{elem.inner_content.mobile_cassa_names}-{elem.inner_content.mobile_cassa_counts}</li>
//          </ul>
//         }})}
      
//     </div>
//   }
//  },[tarif])

//  let Tarif_pro = useCallback(()=>{
//   if(tarif){
//     return <div>
      
//       {tarif.map((elempro, index)=>{
//         if(elempro.tarif_id==2){ 

//          return <ul>
//          <li>{elempro.inner_content.cassa_names}-{elempro.inner_content.cassa_counts}</li>
//          <li>{elempro.inner_content.manager_names}-{elempro.inner_content.manager_counts}</li>
//          <li>{elempro.inner_content.web_names}-{elempro.inner_content.web_counts}</li>
//          <li>{elempro.inner_content.mobile_cassa_names}-{elempro.inner_content.mobile_cassa_counts}</li>
//          </ul>
//         }})}
      
//     </div>
//   }
//  },[tarif])
//  let Tarif_pro_price = useCallback(()=>{
//   if(tarif){
//     return <div>
      
//       {tarif.map((elemproprice, index)=>{
//         if(elemproprice.tarif_id==2){ 
//          return <h3>
//           {elemproprice.tarif_month_prices}
//          </h3>
//         }})}
      
//     </div>
//   }
//  },[tarif])
//  let Tarif_max = useCallback(()=>{
//   if(tarif){
//     return <div>
      
//       {tarif.map((elemmax, index)=>{
//         if(elemmax.tarif_id==3){ 

//          return <ul>
//          <li>{elemmax.inner_content.cassa_names}-{elemmax.inner_content.cassa_counts}</li>
//          <li>{elemmax.inner_content.manager_names}-{elemmax.inner_content.manager_counts}</li>
//          <li>{elemmax.inner_content.web_names}-{elemmax.inner_content.web_counts}</li>
//          <li>{elemmax.inner_content.mobile_cassa_names}-{elemmax.inner_content.mobile_cassa_counts}</li>
//          </ul>
//         }})}
      
//     </div>
//   }
//  },[tarif])
//  let Tarif_max_price = useCallback(()=>{
//   if(tarif){
//     return <div>
      
//       {tarif.map((elemmaxprice, index)=>{
//         if(elemmaxprice.tarif_id==3){ 
//          return <h3>
//           {elemmaxprice.tarif_month_prices}
//          </h3>
//         }})}
      
//     </div>
//   }
//  },[tarif])

//  let Tarif_maxpl = useCallback(()=>{
//   if(tarif){
//     return <div>
      
//       {tarif.map((elemmaxpl, index)=>{
//         if(elemmaxpl.tarif_id==4){ 

//          return <ul>
//          <li>{elemmaxpl.inner_content.cassa_names}-{elemmaxpl.inner_content.cassa_counts}</li>
//          <li>{elemmaxpl.inner_content.manager_names}-{elemmaxpl.inner_content.manager_counts}</li>
//          <li>{elemmaxpl.inner_content.web_names}-{elemmaxpl.inner_content.web_counts}</li>
//          <li>{elemmaxpl.inner_content.mobile_cassa_names}-{elemmaxpl.inner_content.mobile_cassa_counts}</li>
//          </ul>
//         }})}
      
//     </div>
//   }
//  },[tarif])
//  let Tarif_maxpl_price = useCallback(()=>{
//   if(tarif){
//     return <div>
      
//       {tarif.map((elemmaxplprice, index)=>{
//         if(elemmaxplprice.tarif_id==4){ 
//          return <h3>
//           {elemmaxplprice.tarif_month_prices}
//          </h3>
//         }})}
      
//     </div>
//   }
//  },[tarif])

return (
    <>
      <Navbar />
   
      <div className="tarif" id="pro">

        <div className="tarifBox1" >
          <div className="tarifBox1ti">
          <div className="tarifBox1text">
            <h1>Подберите подходящий тариф для себя</h1>
            <p>Пользуйтесь Pcassa бесплатно, или выберите один из платных 
               тарифов, если вам нужна полная мощь автоматизации</p>
          </div>
          <div className="tarifBox1img" id="ggg">
          <img src={Girl} />
          </div>
        </div>
        </div>

        <TarifOwn settarifOwn={settarifOwn} setbycard={setbycard} settransfer={setbycard}  Token={Token_bild} />
         {bycard && <Bycard id='Bycard_content' tarifner={tarifner} tarif={tarif}  setbycard={setbycard} settransfer={settransfer}/>}
         {transfer && <Transfer tarifner={tarifner} tarif={tarif}  setbycard={setbycard} settransfer={settransfer}/>}
         {transferok &&  <Transferok  settransferok={settransferok}/> }
         {cardok &&  <Cardok  setcardok={setcardok}/> }
        <div className="tarifBox4" >
          <div className="tarifBox4text">
          <h1>Автоматизация ритейла</h1>
          <div className="tarifBox4list">
          <div className="tarifBox4list1">
            <ul>
              <li>Работа с любого устройства и платформы</li>
              <li>Отслеживание всех показателей бизнеса</li>
              <li>Регулярные отчеты с полной аналитикой</li>
              <li>Удаленное управление через мобильный менеджер</li>
            </ul>
            
            </div>
            <div className="tarifBox4list2">
            <ul>
              <li>Контроль персонала</li>
              <li>Складской учет и печать ценников</li>
              <li>Удобные API интеграции с любыми сторонними сервисами</li>
              
            </ul>
            
          </div>
          </div>
          </div>
          <div className="tarifBox4img">
          <img src={Tarifimg2} />
                </div>
        </div>
      <div className="tarifBox5">
          <div className="tarifBox5text">
          <h1>Разработаем приложение и сайт для вашего бизнеса</h1>
          <div className="tarifBox5list">
          <div className="tarifBox5list1">
            <ul>
              <li>Управление доставкой</li>
              <li>Фирменные приложение и сайт</li>
              <li>Автоматизация приёма заказов</li>
            </ul>
            
            </div>
            <div className="tarifBox5list2">
            <ul>
              <li>Складской учет и тех. карты</li>
              <li>Контроль персонала</li>
              <li>Регулярные отчеты с полной аналитикой</li>
              
            </ul>
            
          </div>
          </div>
          </div>
          <div className="tarifBox5img">
          <img src={Tarifimg3} />
                </div>
        </div>

        <div  className="tarifBox10" >
          <div className="tarifBox10tb">
            <div className="tarifBox10ti">
              <h1>Оцените удобство Pcassa прямо сейчас</h1>
              <p>
                Просто зарегистрируйтесь в системе, настроить автоматизацию
                можно даже на любом личном устройстве
              </p>
              <div className="tarifBox10Button10">
                <a href="#ggg">
                  <button type="button">Получить Pсassa</button>
                </a>
              </div>
            </div>

            <div className="tarifBox10img">
              <img src={Layers2} />
            </div>
          </div>
        </div>
      </div>

      <Endpart />
      <Footer />
    </>
  );
}
